<template>
  <div class="row">
    <div class="col-12">

      <div class="my-3 d-flex justify-content-between">
        <h1>Проекты</h1>
        <div>
          <router-link :to="{ name: 'CreateProject' }" class="btn btn-success">Создать проект</router-link>
        </div>
      </div>
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between"
          v-for="record in projects"
          :key="record.project.id"
          @click="$router.push(getProjectLink(record.project))"
        >
          <div>
            <div class="text-success" style="font-size: 1.1rem">{{ record.project.name }}</div>
            <div class="text-muted"> {{ record.project.fullSlug }}</div>
          </div>
          <div class="text-muted">
            {{ roles[record.connection.role] }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import repository from '@/repository/ProjectRepository';

export default {
  name: 'ProjectsList',
  data() {
    return {
      projects: [],
      page: 1,
      roles: {
        1: 'Владелец',
        2: 'Участник',
        3: 'Гость',
      },
    };
  },
  created() {
    repository.list(this.page, (response) => {
      this.projects = response.records;
    });
  },
  methods: {
    getProjectLink(project) {
      const data = project.fullSlug.split('/');

      return {
        name: 'ProjectView',
        params: {
          username: data[0],
          projectname: data[1],
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.list-group-item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
</style>
