<template>
<div class="toolka-loading d-flex flex-row">
  <div class="toolka-loading-bar"></div>
  <div class="toolka-loading-bar"></div>
  <div class="toolka-loading-bar"></div>
  <div class="toolka-loading-bar"></div>
</div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style scoped>
.toolka-loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.toolka-loading-bar {
  display: inline-block;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  animation: toolka-loading 1s ease infinite;
  margin-right: 2px;
}

.toolka-loading-bar:nth-child(1) {
  background-color: #18BC9C;
  animation-delay: 0s;
}

.toolka-loading-bar:nth-child(2) {
  background-color: #3498DB;
  animation-delay: 0.09s;
}

.toolka-loading-bar:nth-child(3) {
  background-color: #F39C12;
  animation-delay: .18s;
}

.toolka-loading-bar:nth-child(4) {
  background-color: #E74C3C;
  animation-delay: .27s;
}

@keyframes toolka-loading {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(1, 2.2);
  }
  70% {
    transform: scale(1);
  }
}
</style>
