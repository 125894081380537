<template>
<div class="d-flex flex-column h-100 position-relative overflow-hidden" :class="{ 'task-container': screen }">
  <div ref="filterBox" id="filters" :class="{ 'show-filters': showFilters }" class="default-bg">
    <div class="w-100 p-1">
      <div class="btn btn-sm btn-light d-inline-block d-sm-none" @click="showFilters = !showFilters">Фильтры</div>
    </div>
    <task-filters :project="project" />
  </div>
  <div class="flex-grow-1 d-flex position-relative overflow-hidden">
    <div id="list" :style="listStyleObject" class="d-flex flex-column default-bg">
      <div class="p-1 d-flex d-md-none justify-content-between">
        <div class="btn btn-sm btn-light" @click="showFilters = !showFilters">Фильтры</div>
        <div class="btn btn-sm btn-light" @click="createTask">Создать задачу</div>
      </div>
      <task-list
        :project="project"
        :selectedTask="selectedTask"
        :deletedTask="deletedTask"
        @selectTask="selectTask"
        class="flex-grow-1 overflow-auto"
      />
      <div
        class="position-absolute rounded-circle d-none d-md-flex"
        id="create-tack-button"
        @click="createTask"
      >
        <font-awesome-icon :icon="['fas', 'plus']"/>
      </div>
    </div>
    <div
      id="task"
      :style="taskStyleObject"
      class="default-bg overflow-auto"
      :class="{ 'task-selected': selectedTask !== null }"
    >
      <stick @sizeChange="sizeChange"/>
      <task-view
        v-if="project !== null"
        :small-task="selectedTask"
        :members="members"
        :project="project"
        :settings="settings"
        @deselectTask="deselectTask"
        @selectTask="selectTask"
        @deleteTask="deleteTask"
      />
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable prefer-template */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TaskList from './TaskList.vue';
import ProjectMixin from '@/components/ProjectMixin';
import TaskFilters from './TaskFilters.vue';
import TaskView from './TaskView.vue';
import Stick from '@/views/task/Stick.vue';
import TaskMixin from '@/components/TaskMixin';
import TaskRepository from '@/repository/TaskRepository';

library.add(faPlus);

export default {
  name: 'TaskIndex',
  components: {
    Stick,
    TaskView,
    TaskFilters,
    TaskList,
    FontAwesomeIcon,
  },
  mixins: [ProjectMixin, TaskMixin],
  props: {
    taskLiteralId: [String, Number],
  },
  data() {
    return {
      showFilters: false,
      selectedTask: null,
      deletedTask: null,
      listSize: 50,
      windowSize: 0,
    };
  },
  computed: {
    screen() { return this.$store.getters['task/screen']; },
    listStyleObject() {
      if (typeof window === 'undefined') {
        return { width: 'auto' };
      }
      if (this.windowSize < 576) {
        return {};
      }

      return { width: this.listSize + 'vw' };
    },
    taskStyleObject() {
      if (typeof window === 'undefined') {
        return { width: 'auto' };
      }
      if (this.windowSize < 576) {
        return {};
      }

      return { width: (100 - this.listSize) + 'vw' };
    },
    members() {
      if (this.project === null) {
        return [];
      }
      return this.$store.getters['project/members'][this.project.id] || [];
    },
  },
  watch: {
    project() {
      this.$store.dispatch('project/loadMembers', this.project.id);
    },
  },
  mounted() {
    this.windowResize();
    window.addEventListener('resize', this.windowResize);
    this.$store.dispatch('task/loadStatuses');
    this.whenProjectReady(() => {
      this.$store.dispatch('project/loadMembers', this.project.id);
      this.$store.dispatch('project/loadSettings', this.project.id);
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize);
  },
  methods: {
    selectTask(task) {
      this.$router.push({
        name: 'TaskIndex',
        params: { taskLiteralId: this.getLiteralTaskId(task) },
        query: this.$route.query,
      });
      this.selectedTask = task;
    },
    deselectTask() {
      this.selectedTask = null;
    },
    deleteTask(id) {
      this.selectedTask = null;
      this.deletedTask = id;
    },
    sizeChange(left) {
      this.listSize = left;
    },
    windowResize() {
      this.windowSize = window.innerWidth;
    },
    createTask() {
      TaskRepository.create(this.project.id, (response) => {
        this.selectTask(response);
      });
    },
  },
};
</script>

<style scoped lang="scss">
#filters {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: left .2s ease;
  box-shadow: 0px 3px 20px 1px rgba(100, 100, 100, 0.05);

  &.show-filters {
    left: 0;
  }

  @media (min-width: 576px) {
    position: relative;
    width: auto;
    height: auto;
    left: auto;
    z-index: 5;
  }
}

#list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  @media (min-width: 576px) {
    position: relative;
    width: 30vW;
  }
}

#task {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  z-index: 3;
  transition: left .3s ease;

  &.task-selected {
    left: 0;
  }

  @media (min-width: 576px) {
    position: relative;
    width: 70vW;
    left: auto;
  }
}

#create-tack-button {
  left: 2rem;
  bottom: 4rem;
  background-color: var(--success);
  /*padding: 0.5rem;*/
  color: white;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    opacity: .8;
  }
}

.task-container {
  @media (min-width: 1200px) {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 1140px;
    border-right: 1px;
    border-left: 1px;
    border-color: var(--light);
    border-style: solid;
    border-top: 0;
    border-bottom: 0;
  }
}

body[data-theme="dark"] {
  #filters {
    box-shadow: 0px 3px 20px 1px rgba(255, 255, 255, 0.05);
  }
  #create-tack-button {
    background-color: var(--dark-primary);
  }
}
</style>
