<template>
  <div style="width: 250px;margin: 0 auto;">
    <vue-headful
      title="Восстановление пароля"
      description="осстановление пароля"
    />
    <div v-show="loading">Загрузка. Жди завершения невыключая копьютера!</div>
    <div v-show="step === 0">
      <form @submit.prevent="sendRequest">
        <div class="form-group">
          <input type="text" v-model="email" :class="{'is-invalid': errors.email}" class="form-control">
          <div class="invalid-feedback" v-show="errors.email">
            {{ errors.email }}
          </div>
        </div>
        <button type="submit" class="btn btn-success">Отправить запрос</button>
      </form>
    </div>
    <div v-show="step === 1">
      <form @submit.prevent="requestConfirm">
        Проверь почту и введи сюда код из письма
        <div class="form-group">
          <input type="text" v-model="code" :class="{'is-invalid': errors.code}" class="form-control">
          <div class="invalid-feedback" v-show="errors.code">
            {{ errors.code }}
          </div>
        </div>
        А еще пароль придумай, но не тупи
        <div class="form-group">
          <input type="password" v-model="password" :class="{'is-invalid': errors.password}" class="form-control">
          <div class="invalid-feedback" v-show="errors.password">
            {{ errors.password }}
          </div>
        </div>
        <button type="submit" class="btn btn-success">Завершить восстановление</button>
      </form>
    </div>
    <router-link :to="{name: 'SignIn'}">Вход</router-link>
    <router-link :to="{name: 'SignUp'}">Регистрация</router-link>
  </div>
</template>

<script>
import repository from '../../repository/UserRepository';

export default {
  name: 'PasswordRecovery',
  data() {
    return {
      step: 0,
      email: 'test4@test.test',
      password: '12345678',
      code: '1234',
      loading: false,
      errors: {},
    };
  },
  methods: {
    sendRequest() {
      this.loading = true;
      repository.passwordForgot({ email: this.email }, () => {
        this.loading = false;
        this.step = 1;
      }, (fields) => {
        this.loading = false;
        this.errors = fields;
      });
    },
    requestConfirm() {
      this.loading = true;
      const { email, password, code } = this;
      repository.passwordConfirm({
        email,
        password,
        code,
      }, () => {
        this.loading = false;

        repository.getMyProfile((data) => {
          this.$store.commit('signin', data);
          this.$router.push('/feed');
        });
      }, (fields) => {
        this.loading = false;
        this.errors = fields;
      });
    },
  },
};
</script>
