import Vue from 'vue';
import ProjectRepository from '@/repository/ProjectRepository';

export default {
  namespaced: true,
  state: {
    projects: {},
    members: {},
    settings: {},
  },
  getters: {
    projects: state => state.projects,
    members: state => state.members,
    settings: state => state.settings,
  },
  mutations: {
    setProject(state, { slug, project }) {
      Vue.set(state.projects, slug, project);
    },
    breakProjects(state) {
      Vue.set(state, 'projects', {});
    },
    setMembers(state, { id, members }) {
      Vue.set(state.members, id, members);
    },
    setSettings(state, { id, settings }) {
      Vue.set(state.settings, id, settings);
    },
  },
  actions: {
    loadProjects({ commit }, slug) {
      if (typeof this.getters['project/projects'][slug] !== 'undefined') {
        return null;
      }
      return new Promise((resolve) => {
        ProjectRepository.getBySlug(slug, (project) => {
          commit('setProject', {
            slug,
            project,
          });
          resolve();
        });
      });
    },
    loadMembers({ commit }, id) {
      if (typeof this.getters['project/members'][id] !== 'undefined') {
        return null;
      }
      return new Promise((resolve) => {
        ProjectRepository.listMembers(id, 1, (response) => {
          commit('setMembers', {
            id,
            members: response.records,
          });
          resolve();
        });
      });
    },
    loadSettings({ commit }, id) {
      return new Promise((resolve) => {
        ProjectRepository.getSettingsList(id, (response) => {
          commit('setSettings', {
            id,
            settings: response,
          });
          resolve();
        });
      });
    },
  },
};
