<template>
  <div class="form-group" :class="additionalClasses">
    <slot></slot>
    <div class="text-danger" v-show="typeof errors[field] !== 'undefined'">
      {{ errors[field] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    additionalClasses: {
      type: [String, Object, Array],
      description: 'Additional classes for form-group element',
    },
    field: {
      type: String,
      description: 'Field name for errors showing',
    },
    errors: {
      type: Object,
    },
  },
};
</script>
