export default {
  computed: {
    isStatusesReady() {
      return this.$store.getters['task/statuses'] !== null;
    },
  },
  methods: {
    getStatusById(id) {
      if (this.$store.getters['task/statuses'] === null) {
        return null;
      }
      return this.$store.getters['task/statuses'].statuses[id];
    },
  },
};
