import http from './Http';

function signUp(data, onSuccess, onNotValid) {
  http.post('/user/sign-up', data, onSuccess, onNotValid);
}

function signIn(data, onSuccess, onError) {
  http.post('/user/sign-in', data, onSuccess, onError);
}

function signOut(onSuccess) {
  http.post('/user/logout', {}, onSuccess);
}

function getMyProfile(onSuccess, onError) {
  http.get('/user/my-profile', {}, onSuccess, onError);
}

function changePassword(data, onSuccess, onNotValid) {
  http.post('/user/change-password', data, onSuccess, onNotValid);
}

function uploadAvatar(data, onSuccess, onError, onProgress) {
  http.file('/user/upload-avatar', data, onSuccess, onError, onError, onProgress);
}

export default {
  signUp,
  signIn,
  signOut,
  getMyProfile,
  changePassword,
  uploadAvatar,
};
