<template>
<div>
  <h1>Мобильного приложения нет!</h1>
  <p>И не будет.</p>
  <p>Почему?</p>
  <p>Потому что это выгодно вам!</p>
  <p>И нам!</p>

  <h2>Почему это выгодно вам?</h2>
  <div class="list-group mb-4">
    <div class="list-group-item">
      <div class="number">1</div>
      <h5 class="mb-1">Не тратите место на своем смарфоне</h5>
      <p class="mb-1">
        Мобильное приложение необходимо скачать из магазина и установить. Веб приложение открывается в браузере.
      </p>
    </div>
    <div class="list-group-item">
      <div class="number">2</div>
      <h5 class="mb-1">Мгновенные обновления</h5>
      <p class="mb-1">
        Мобильное приложение необходимо обновлять заного скачивая исполняемый файл. Веб приложение открывается прямо с сервера.
      </p>
    </div>
    <div class="list-group-item">
      <div class="number">3</div>
      <h5 class="mb-1">Мощность</h5>
      <p class="mb-1">
        Веб приложение не уступает мобильному приложению.
      </p>
    </div>
    <div class="list-group-item">
      <div class="number">4</div>
      <h5 class="mb-1">Экономия денег</h5>
      <p class="mb-1">
        Если в Тулке будут платные сервисы, то их цена не будет в себя включать стоимость разработки и поддержки мобильных приложений.
      </p>
    </div>
  </div>

  <h2>Почему это выгодно нам?</h2>
  <div class="list-group mb-5">
    <div class="list-group-item">
      <div class="number">1</div>
      <h5 class="mb-1">Меньший объем работы</h5>
      <p class="mb-1">
        Не нужно писать код мобильного приложения, рисовать дизайн для мобильного приложения и писать тесты мобильного приложения.
      </p>
    </div>
    <div class="list-group-item">
      <div class="number">2</div>
      <h5 class="mb-1">Надежность</h5>
      <p class="mb-1">
        Чем меньше кода тем меньше мест, где можно допустить ошибку.
        Нет необходимости поддерживать несколько устаревших версий мобильных приложений и апи.
      </p>
    </div>
    <div class="list-group-item">
      <div class="number">3</div>
      <h5 class="mb-1">Концентрация</h5>
      <p class="mb-1">
        Мы тратим время на разработку только веб приложения. Нам не приходится думать о том как что-то будет работать в мобильном приложении.
      </p>
    </div>
    <div class="list-group-item">
      <div class="number">4</div>
      <h5 class="mb-1">Единообразие</h5>
      <p class="mb-1">
        Нет нескольких версий приложения
      </p>
    </div>
    <div class="list-group-item">
      <div class="number">5</div>
      <h5 class="mb-1">Экономия денег</h5>
      <p class="mb-1">
        Не приходится тратить деньги на разработку мобильных приложений.
      </p>
    </div>
  </div>
  <h2>Добавление на главный экран</h2>
  Вы можете добавить ссылку на Тулку на главный экран своего смартфона и открывать её одним нажатием не переходя в браузер.

  <h3><a href="#android" name="android">Добавление на главный экран для смартфонов на андроиде</a></h3>
  <div class="list-group mb-4">
    <div class="list-group-item">
      <div class="number">1</div>
      <h5 class="mb-1">Откройте браузер и перейдите на главную страницу сайта</h5>
      <p class="mb-1 text-muted">
        Пример показан для браузера Хром. В других браузерах эти кнопки могут быть расположены в других местах.
      </p>
    </div>
    <div class="list-group-item">
      <div class="number">2</div>
      <h5 class="mb-1">Перейдите в меню и выберите пункт "Добавить на главный экран"</h5>
      <p class="mb-1">
        <img src="/img/site/how-to/android-add-button.jpg" />
      </p>
    </div>
    <div class="list-group-item">
      <div class="number">3</div>
      <h5 class="mb-1">В появившемся окошке нажмите "ОК" и кнопка появится на главном экране</h5>
      <p class="mb-1">
        <img src="/img/site/how-to/android-desktop.jpg" />
      </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'NoApp',
};
</script>

<style scoped>
.number {
  font-size: 60px;
  color: var(--success);
  float: left;
  margin-right: 20px;
  line-height: 50px;
}
</style>
