import axios from 'axios';

axios.defaults.withCredentials = true;
const host = window.toolkaConfig.apiHost;

function logout() {
  window.application.$store.commit('signout');
  window.application.$router.push({
    name: 'SignIn',
    query: { error: 'auth-lost' },
  });
}

function offline(uri) {
  window.application.$store.commit('OFFLINE');
  console.error(`Offline value ${uri}`);
}

// function online() {
//   window.application.$store.commit('ONLINE');
// }

function get(uri, data, onSuccess, onNotValid, onError) {
  axios.get(host + uri, {
    params: data,
  })
    .then((response) => {
      if (response.data.code === 0) {
        onSuccess(response.data.data);
      }
      if (response.data.code === 2) {
        onNotValid(response.data.fields);
      }
    })
    .catch((error) => {
      if (!error.response) {
        offline(uri);
        return;
      }
      if (error.response.data.code === 4) {
        logout();
        return;
      }
      onError(error.data);
    });
}

function post(uri, data, onSuccess, onNotValid, onError) {
  axios.post(host + uri, data)
    .then((response) => {
      if (response.data.code === 0) {
        onSuccess(response.data.data);
      }
      if (response.data.code === 2) {
        onNotValid(response.data.fields);
      }
    })
    .catch((error) => {
      const response = error.response.data;
      if (error.response.data.code === 4) {
        logout();
        return;
      }
      if (typeof onError !== 'undefined') {
        onError(response.data);
      }
      // console.error(response);
    });
}

function deleteMethod(uri, data, onSuccess, onNotValid, onError) {
  axios.delete(host + uri, { data })
    .then((response) => {
      if (response.data.code === 0) {
        onSuccess(response.data.data);
      }
      if (response.data.code === 2) {
        onNotValid(response.data.fields);
      }
    })
    .catch((error) => {
      if (error.response.data.code === 4) {
        logout();
        return;
      }
      if (typeof onError !== 'undefined') {
        onError(error.data);
      }
      // console.error(error);
    });
}

function put(uri, data, onSuccess, onNotValid, onError) {
  axios.put(host + uri, data)
    .then((response) => {
      if (response.data.code === 0) {
        onSuccess(response.data.data);
      }
      if (response.data.code === 2) {
        onNotValid(response.data.fields);
      }
    })
    .catch((error) => {
      if (typeof onError !== 'undefined') {
        onError(error.data);
      }
      if (error.response.data.code === 4) {
        logout();
      }
      // console.error(error);
    });
}

function file(uri, data, onSuccess, onNotValid, onError, onProgress) {
  axios.post(host + uri, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onProgress,
  })
    .then((response) => {
      if (response.data.code === 0) {
        onSuccess(response.data.data);
      }
      if (response.data.code === 2) {
        onNotValid(response.data.fields);
      }
    })
    .catch((error) => {
      if (typeof onError !== 'undefined') {
        onError(error.data);
      }
      if (error.response.data.code === 4) {
        logout();
      }
      // console.error(response);
    });
}

export default {
  get,
  post,
  file,
  put,
  delete: deleteMethod,
};
