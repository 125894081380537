<template>
<div
  :class="{ selected: selectedTaskId === task.id }"
  @click="$emit('selectTask', task)"
  class="task d-flex justify-content-between py-2 px-2 p1-2"
>
  <div class="flex-grow-1 text-truncate d-flex align-items-center">
    <span class="text-muted ml-1" v-show="lineOptions.key.value">
      {{iteratorKey + 1}}
    </span>
    <span class="badge badge-info ml-1" v-show="lineOptions.id.value">
      {{ getLiteralTaskId(task) }}
    </span>
    <span class="ml-1 text-truncate pr-1" v-show="lineOptions.title.value">
      {{ task.title }}
    </span>
  </div>

  <div class="text-right d-flex align-items-center">
    <font-awesome-icon
      v-show="task.priority === 4 && lineOptions.priorityNightmare.value"
      :icon="['fas', 'exclamation-triangle']"
      title="Приоритет: Срочно!"
      class="text-danger mr-1" />
    <span v-show="lineOptions.priority.value" class="badge badge-dark mr-1">
      {{ priority.label }}
    </span>
    <span :class="getStatusBadgeClasses()" class="badge mr-1" v-show="lineOptions.status.value"
          v-if="isStatusesReady">
      {{ status.label }}
    </span>
    <img
      :src="task.executor.avatar.replace(/{size}/g, '90x90i')"
      class="rounded-lg float-right mr-1"
      style="width: 20px"
      v-if="task.executor"
      v-show="lineOptions.executor.value"
    >
  </div>
</div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import StatusMixin from '@/components/task/StatusMixin';
import TaskMixin from '@/components/TaskMixin';
import PriorityMixin from '@/components/task/PriorityMixin';

library.add(faExclamationTriangle);

export default {
  name: 'ListLine',
  mixins: [StatusMixin, PriorityMixin, TaskMixin],
  components: {
    FontAwesomeIcon,
  },
  props: {
    task: Object,
    selectedTaskId: Number,
    iteratorKey: Number,
    lineOptions: Object,
  },
  computed: {
    status() {
      return this.getStatusById(this.task.status);
    },
    priority() {
      return this.getPriorityById(this.task.priority);
    },
  },
  methods: {
    getStatusBadgeClasses() {
      const name = `badge-${this.status.group.color}`;
      const classes = {};
      classes[name] = true;

      return classes;
    },
  },
};
</script>

<style scoped lang="scss">
.task {
  border-bottom: 1px solid var(--light);
  /*padding: 4px 10px;*/
  /*height: 34px;*/

  &:hover {
    cursor: pointer;
    background-color: var(--light);
  }

  &.selected {
    background-color: var(--primary);
    color: white;
  }
}
body[data-theme="dark"] {
  .task {
    border-bottom: 1px solid black;
    &:hover{
      background-color: var(--primary);
    }
  }
}
</style>
