<template>
  <div>
    <slot v-if="this.condition"></slot>
  </div>
</template>

<script>
export default {
  name: 'BlockLoader',
  props: ['condition'],
};
</script>
