<template>
<form id="uploadForm" class="mt-3">
  <label class="w-100 m-0">
    <input type="file" name="avatar" class="d-none" @change="uploadAvatar" id="profile-upload-avatar">
    <div class="btn btn-outline-primary w-100 pointer" :class="{disabled: progress !== null}">Загрузить аватар</div>
  </label>
  <div class="progress mt-1" v-if="progress !== null">
    <div class="progress-bar"
         role="progressbar"
         :style="{ width: progress + '%' }"
         :aria-valuenow="progress"
         aria-valuemin="0"
         aria-valuemax="100"></div>
  </div>
  <div class="text-danger text-center" v-if="error">{{error}}</div>
</form>
</template>

<script>
import UserRepository from '@/repository/UserRepository';

export default {
  name: 'Avatar',
  data() {
    return {
      progress: null,
      error: null,
    };
  },
  methods: {
    uploadAvatar() {
      const data = new FormData(document.getElementById('uploadForm'));
      const imageFile = document.querySelector('#profile-upload-avatar');
      data.append('avatar', imageFile.files[0]);

      this.progress = 0;
      UserRepository.uploadAvatar(data, () => {
        this.progress = null;
        this.$emit('loaded');
      },
      () => {
        this.error = 'Что-то пошло не так. Попробуйте снова.';
        this.progress = null;
      },
      (progressEvent) => {
        this.progress = Math.round(progressEvent.loaded / (progressEvent.total / 100));
      });
    },
  },
};
</script>

<style scoped>

</style>
