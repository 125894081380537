<template>
<block-loader v-slot :condition="dashboard && project" class="row">
  <div class="col-12 pt-3 d-flex justify-content-between">
    <h1>{{project.name}}</h1>
    <div>
      <router-link :to="{
          name: 'UpdateProject',
          params: {
            username: username,
            projectname: projectname,
          },
        }" class="btn btn-primary d-block">
        Редактировать
      </router-link>
    </div>
  </div>

  <div class="col-12">
    <p>{{project.description}}</p>
  </div>

  <div class="col-12 col-lg-4 p-2">
    <div class="card h-100">
      <div class="card-body" style="padding-bottom: 50px;">
        <h5 class="card-title">Задачи</h5>
        Всего: {{ dashboard.tasksQuantity }}
        <p class="card-text">Модуль задач.</p>
        <router-link :to="{
        name: 'TaskIndex',
        params: {
          username: username,
          projectname: projectname,
        },
      }" class="btn btn-primary btn-right-bottom">Перейти к задачам</router-link>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-4 p-2">
    <div class="card h-100">
      <div class="card-body" style="padding-bottom: 50px;">
        <h5 class="card-title">Участники</h5>
        Всего: {{ dashboard.membersQuantity }}
        <p class="card-text">Управляйте доступом людей к проекту.</p>
        <router-link :to="{
        name: 'Members',
        params: {
          username: username,
          projectname: projectname,
        },
      }" class="btn btn-primary btn-right-bottom">Перейти к списку участников</router-link>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-4 p-2">
    <div class="card h-100">
      <div class="card-body" style="padding-bottom: 50px;">
        <h5 class="card-title">Настройки</h5>
        <p class="card-text">Настройки необходимые для кастомизации проекта.</p>
        <router-link :to="{
        name: 'Settings',
        params: {
          username: username,
          projectname: projectname,
        },
      }" class="btn btn-primary btn-right-bottom">Перейти к настройкам</router-link>
      </div>
    </div>
  </div>
</block-loader>
</template>

<script>
import repository from '../../repository/ProjectRepository';
import BlockLoader from '../../components/BlockLoader.vue';

export default {
  name: 'ProjectView',
  components: { BlockLoader },
  props: ['username', 'projectname'],
  data() {
    return {
      dashboard: null,
    };
  },
  computed: {
    slug() {
      return `${this.username}/${this.projectname}`;
    },
    project() {
      return this.$store.getters['project/projects'][this.slug];
    },
  },
  mounted() {
    this.$store.dispatch('project/loadProjects', this.slug).then(() => {
      repository.dashboard(this.project.id, (dashboardResponse) => {
        this.dashboard = dashboardResponse;
      });
    });
  },
};
</script>

<style scoped>
.btn-right-bottom {
  position: absolute;
  display: block;
  right: 10px;
  bottom: 10px;
}
</style>
