export default {
  methods: {
    getLiteralTaskId(task) {
      if (task.literal === '') {
        return task.literalId;
      }
      return `${task.literal}-${task.literalId}`;
    },
  },
};
