<template>
  <footer class="footer mt-auto py-3">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <div class="text-muted">Toolka &copy; 2014-{{ year }}</div>
          <router-link :to="{ name: 'Blog' }">Блог</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
