export default {
  data() {
    return {
      itWasVisible: false,
      observer: null,
      isInfiniteLazyLoad: false,
    };
  },
  mounted() {
    // eslint-disable-next-line
    if (window['IntersectionObserver']) {
      this.observer = new IntersectionObserver(this.observerCallback, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      });
      this.observer.observe(this.$refs.lazyLoadRef);
    } else {
      this.iSeeYou();
    }
  },
  methods: {
    observerCallback(entries, observer) {
      if (entries[0].isIntersecting) {
        this.iSeeYou();
        if (!this.isInfiniteLazyLoad) {
          observer.unobserve(entries[0].target);
          this.observer.disconnect();
          this.observer = null;
        }
      }
    },
    iSeeYou() {
      this.itWasVisible = true;
      if (typeof this.onVisible === 'function') {
        this.onVisible();
      }
    },
    isElementVisible() {
      let el = this.$refs.lazyLoadRef;
      let top = el.offsetTop;
      let left = el.offsetLeft;
      const width = el.offsetWidth;
      const height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top < (window.pageYOffset + window.innerHeight)
        && left < (window.pageXOffset + window.innerWidth)
        && (top + height) > window.pageYOffset
        && (left + width) > window.pageXOffset
      );
    },
  },
};
