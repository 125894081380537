import http from './Http';

function list(page, onSuccess) {
  http.get('/project/list', { page }, onSuccess);
}

function getBySlug(slug, onSuccess) {
  http.get('/project/view-by-slug', { slug }, onSuccess);
}

function dashboard(id, onSuccess) {
  http.get('/project/dashboard', { id }, onSuccess);
}

function listMembers(projectId, page, onSuccess) {
  http.get('/project/list-members', { projectId, page }, onSuccess);
}

function addMember(projectId, email, onSuccess, onNotValid) {
  http.post('/project/add-member', { projectId, email }, onSuccess, onNotValid);
}

function removeMember(projectId, userId, onSuccess) {
  http.delete('/project/remove-member', { projectId, userId }, onSuccess);
}

function getSettingsList(projectId, onSuccess) {
  http.get('/project/list-settings', { id: projectId }, onSuccess);
}

function updateSettings(data, onSuccess, onNotValid) {
  http.post('/project/update-settings', data, onSuccess, onNotValid);
}

function create(data, onSuccess, onNotValid) {
  http.post('/project/create', data, onSuccess, onNotValid);
}

function update(data, onSuccess, onNotValid) {
  http.put('/project/update', data, onSuccess, onNotValid);
}

export default {
  list,
  getBySlug,
  dashboard,
  listMembers,
  addMember,
  removeMember,
  getSettingsList,
  updateSettings,
  create,
  update,
};
