import Vue from 'vue';
import vueHeadful from 'vue-headful';
import moment from 'moment';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import './assets/main.scss';
import DefaultLayout from './views/layouts/DefaultLayout.vue';
import WideLayout from './views/layouts/WideLayout.vue';
import WideWithoutFooterLayout from './views/layouts/WideWithoutFooterLayout.vue';

Vue.component('default-layout', DefaultLayout);
Vue.component('wide-layout', WideLayout);
Vue.component('wide-without-footer-layout', WideWithoutFooterLayout);
Vue.config.productionTip = false;
Vue.component('vue-headful', vueHeadful);
Vue.filter(
  'timestampToDate',
  value => moment(moment.unix(value))
    .format('DD.MM.YYYY'),
);
Vue.filter(
  'timestampToDateTime',
  value => moment(moment.unix(value))
    .format('DD.MM.YYYY HH:mm'),
);


window.application = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
