<template>
<div id="stick" class="d-none d-sm-block border-right border-left"
     :title="size + '%'"
     @mousedown="mouseDown"
     @mouseup="mouseUp">
</div>
</template>

<script>
export default {
  name: 'Stick',
  data() {
    return {
      drag: false,
      size: 50,
    };
  },
  methods: {
    mouseDown() {
      this.drag = true;
    },
    mouseUp() {
      this.drag = false;
    },
    mouseMove(event) {
      if (this.drag === false) {
        return;
      }
      if (event.stopPropagation) event.stopPropagation();
      if (event.preventDefault) event.preventDefault();
      const onePercent = window.innerWidth / 100;
      let size = Math.floor(event.clientX / onePercent);
      if (size < 30) {
        size = 30;
      }
      if (size > 70) {
        size = 70;
      }
      this.size = size;
      this.$emit('sizeChange', size);

      // eslint-disable-next-line consistent-return
      return false;
    },
  },
  mounted() {
    window.addEventListener('mousemove', this.mouseMove);
    window.addEventListener('mouseup', this.mouseUp);
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.mouseUp);
  },
};
</script>

<style scoped lang="scss">
#stick {
  width: 3px;
  height: 100%;
  position: absolute;
  left: -2px;
  top: 0;
  cursor: ew-resize;
  background-color: var(--body-bg);
}
</style>
