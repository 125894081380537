import http from './Http';

function list(filters, onSuccess) {
  http.get('/task/list', filters, onSuccess);
}

function getStatuses(onSuccess) {
  http.get('/task/statuses', {}, onSuccess);
}

function update(data, onSuccess) {
  http.post('/task/update', data, onSuccess);
}

function view(id, onSuccess) {
  http.get('/task/view', { id }, onSuccess);
}

function viewByLiteralId(literalId, projectId, onSuccess) {
  http.get('/task/view', { literalId, projectId }, onSuccess);
}

function create(projectId, onSuccess) {
  http.post('/task/create', { projectId }, onSuccess);
}

function deleteTask(id, onSuccess) {
  http.delete('/task/delete', { id }, onSuccess);
}

export default {
  list,
  getStatuses,
  update,
  view,
  viewByLiteralId,
  create,
  deleteTask,
};
