<template>
<div v-if="project" class="h-100 task-list-block d-flex flex-column">
  <div class="task-list overflow-auto">
    <list-line
      v-for="(task, key) in tasks"
      :task="task"
      :key="task.id"
      :iteratorKey="key"
      :lineOptions="lineOptions"
      :class="{ selected: selectedTaskId === task.id }"
      @selectTask="selectTask"
    />
    <infinite-loading @infinite="infiniteHandler" :infiniteId="infiniteId">
      <loading slot="spinner" style="position: relative;" />
      <div slot="no-more">
        <div>&nbsp;</div>
      </div>
      <div slot="no-results">
        <div>&nbsp;</div>
      </div>
    </infinite-loading>
  </div>

  <div class="status-bar position-absolute w-100 d-flex justify-content-between"
       style="bottom: 0;left: 0;">
    <div>
      <div class="reload btn btn-success btn-sm pointer rounded-0" @click="reload">
        <font-awesome-icon :icon="['fas', 'sync']"/>
      </div>
      Всего: {{ total }}
    </div>
    <div>
      <b-dropdown
        variant="link"
        toggle-tag="div"
        dropup
        size="sm"
        class="pointer"
        text="Поля"
        toggle-class="text-decoration-none"
        no-caret
        no-flip
      >
        <template v-slot:default>
        <a class="dropdown-item"
           v-for="(option, key) in lineOptions"
           href="#"
           @click.prevent.stop="changeLineOptions(key)"
           :key="key">
          <span :class="{ invisible: !option.value } ">✓</span> {{option.label}}
        </a>
        </template>
      </b-dropdown>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable no-restricted-syntax, guard-for-in, no-prototype-builtins */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { BDropdown } from 'bootstrap-vue';
import InfiniteLoading from '@/components/lazy-load/InfiniteLoading.vue';
import TaskRepository from '@/repository/TaskRepository';
import TaskMixin from '@/components/TaskMixin';
import StatusMixin from '@/components/task/StatusMixin';
import ListLine from '@/views/task/ListLine.vue';
import Loading from '@/views/Loading.vue';
import WatchQueryMixin from '@/components/WatchQueryMixin';

library.add(faSync);

const availableFilters = [
  'query',
  'statuses',
  'sortOrder',
  'executor',
  'members',
];

export default {
  name: 'TaskList',
  components: {
    Loading,
    ListLine,
    FontAwesomeIcon,
    InfiniteLoading,
    'b-dropdown': BDropdown,
  },
  mixins: [
    TaskMixin,
    StatusMixin,
    WatchQueryMixin,
  ],
  props: {
    project: Object,
    selectedTask: Object,
    deletedTask: Number,
  },
  data() {
    return {
      tasks: [],
      infiniteId: 1,
      page: 1,
      total: 0,
      lineOptions: {
        key: { label: 'Номер', value: false },
        id: { label: 'Идентификатор', value: true },
        title: { label: 'Заголовок', value: true },
        priority: { label: 'Приоритет', value: false },
        priorityNightmare: { label: 'Приоритет "Срочно"', value: true },
        status: { label: 'Статус', value: true },
        executor: { label: 'Исполнитель', value: true },
      },
    };
  },
  mounted() {
    const values = this.$store.getters['task/lineOptions'];
    for (const key in this.lineOptions) {
      if (values.hasOwnProperty(key)) {
        this.lineOptions[key].value = values[key];
      }
    }
  },
  computed: {
    selectedTaskId() {
      if (this.selectedTask === null) {
        return 0;
      }
      return this.selectedTask.id;
    },
  },
  watch: {
    selectedTask() {
      if (this.selectedTask === null) {
        return;
      }
      for (const i in this.tasks) {
        if (this.tasks[i].id === this.selectedTask.id) {
          this.$set(this.tasks, i, this.selectedTask);
        }
      }
    },
    deletedTask() {
      for (const i in this.tasks) {
        if (this.tasks[i].id === this.deletedTask) {
          this.$delete(this.tasks, i);
        }
      }
    },
  },
  watchQuery(context) {
    context.reload();
  },
  methods: {
    infiniteHandler($state) {
      const data = {};
      for (const key in availableFilters) {
        const field = availableFilters[key];
        if (typeof this.$route.query[field] !== 'undefined') {
          data[field] = this.$route.query[field];
        }
      }

      if (typeof data.members !== 'undefined') {
        // TODO переделать экзекьютора на список мемберсов
        data.executor = data.members;
      }
      data.projectId = this.project.id;
      data.page = this.page;

      TaskRepository.list(data, (response) => {
        this.total = response.total;
        this.page += 1;
        if (response.records.length) {
          this.tasks.push(...response.records);
          if (response.hasNext) {
            $state.loaded();
          } else {
            $state.complete();
          }
        }
      });
    },
    reload() {
      this.page = 1;
      this.total = 0;
      this.tasks = [];
      this.infiniteId += 1;
    },
    selectTask(task) {
      this.$emit('selectTask', task);
    },
    changeLineOptions(key) {
      this.lineOptions[key].value = !this.lineOptions[key].value;
      const values = this.getObjectForStorage();
      this.$store.commit('task/setLineOptions', values);
    },
    getObjectForStorage() {
      const result = {};
      for (const key in this.lineOptions) {
        result[key] = this.lineOptions[key].value;
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss">

.task-list {
  padding-bottom: 130px;
}

.task-list-block:hover .total {
  display: block;
}

.status-bar {
  height: 29px;
  /*display: none;*/
  /*font-size: 12px;*/
  color: var(--gray);
  background-color: var(--light);
  /*opacity: .9;*/
}

body[data-theme="dark"] {
  .status-bar {
    color: var(--dark-light);
    background-color: var(--dark-dark);
  }
}
</style>
