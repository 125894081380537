const priorities = [
  { id: 1, label: 'Низкий' },
  { id: 2, label: 'Средний' },
  { id: 3, label: 'Высокий' },
  { id: 4, label: 'Кошмар' },
];

export default {
  methods: {
    getPriorityById(id) {
      // eslint-disable-next-line no-restricted-syntax
      for (const i in priorities) {
        if (priorities[i].id === id) {
          return priorities[i];
        }
      }
      return null;
    },
  },
};
