<template>
<b-navbar toggleable="lg" type="dark">
  <div class="container-fluid">
    <b-navbar-brand>Toolka<sup v-html="postfix"></sup></b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="!$store.getters.isGuest">
        <li class="nav-item">
          <router-link
            :to="{name: 'ProjectsList', params: {username: $store.getters.user.username}}"
            class="nav-link"
          >
            Мои проекты
          </router-link>
        </li>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <li class="nav-item" v-if="$store.getters.isGuest">
          <router-link :to="{name: 'SignIn'}" class="nav-link">Вход</router-link>
        </li>
        <b-nav-item-dropdown right v-else :no-caret="true">
          <template v-slot:button-content>
            <img :src="$store.getters.user.avatar.replace(/{size}/g, '90x90i')"
                 class="rounded float-left"
                 style="width: 40px">
          </template>
          <b-dropdown-item :to="{name: 'MyProfile'}">
            Профиль
          </b-dropdown-item>
          <b-dropdown-item @click="signOut">
            Выход
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </div>
</b-navbar>
</template>

<script>
import Vue from 'vue';
import { NavbarPlugin } from 'bootstrap-vue';
import repository from '@/repository/UserRepository';

Vue.use(NavbarPlugin);

export default {
  name: 'app',
  computed: {
    postfix() {
      switch (window.location.host) {
        case 'test.toolka.net':
          return 'test';
        case 'toolka.net':
          return '&alpha;';
        default:
          return 'dev';
      }
    },
  },
  // beforeCreate() {
  //   if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //     document.querySelector('body').setAttribute('data-theme', 'dark');
  //   }
  // },
  methods: {
    signOut() {
      repository.signOut(() => {
        this.$store.commit('signout');
        this.$router.push({ name: 'MainPage' });
      });
    },
    // toggleTheme(value) {
    //   this.$store.commit('setTheme', value ? 'dark' : 'light');
    //   document.querySelector('body').setAttribute('data-theme', value ? 'dark' : 'light');
    // },
  },
};
</script>
