<template>
  <form @submit.prevent="changePassword">
    <div class="alert alert-success" v-if="alert">{{alert}}</div>
    <form-group field="password" :errors="errors">
      <label>Пароль</label>
      <input type="password" v-model="password" class="form-control form-control-lg">
    </form-group>
    <form-group field="confirm" :errors="errors">
      <label>Повторите пароль</label>
      <input type="password" v-model="confirm" class="form-control form-control-lg">
    </form-group>

    <button type="submit" class="btn btn-success">Изменить пароль</button>
  </form>
</template>

<script>
import repository from '@/repository/UserRepository';
import FormGroup from '@/components/FormGroup.vue';

export default {
  name: 'ChangePassword',
  components: { FormGroup },
  props: ['model'],
  data() {
    return {
      password: '',
      confirm: '',
      errors: {},
      alert: '',
    };
  },
  methods: {
    changePassword() {
      repository.changePassword({
        password: this.password,
        confirm: this.confirm,
      },
      () => {
        this.errors = {};
        this.password = '';
        this.confirm = '';
        this.alert = 'Пароль успешно изменен.';
        setTimeout(() => { this.alert = ''; }, 5000);
      },
      (errors) => {
        this.errors = errors;
      });
    },
  },
};
</script>

<style scoped>

</style>
