<template>
<!--  <div id="app" class="h-100">-->
    <component :is="layout" class="d-flex flex-column h-100">
      <router-view/>
    </component>
<!--  </div>-->
</template>

<script>
import AppHeader from './views/AppHeader.vue';
import AppFooter from './views/AppFooter.vue';
import SiteRepository from '@/repository/SiteRepository';
import UserRepository from '@/repository/UserRepository';

export default {
  name: 'app',
  components: {
    AppHeader,
    AppFooter,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout';
    },
  },
  created() {
    SiteRepository.getStatus((response) => {
      if (!response.isGuest && this.$store.getters.isGuest) {
        UserRepository.getMyProfile((data) => {
          this.$store.commit('signin', data);
        });
      }
    });
  },
};
</script>
