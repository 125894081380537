<template>
  <div id="app">
    <overlay :show="loading">
      <form class="form-signin bg-light" style="border-radius: 5px;" @submit.prevent="signIn">
        <div class="text-center mb-4">
          <h1 class="h3 mb-1 font-weight-normal text-primary">Toolka</h1>
          <p class="text-primary">Toolbelt on your ass</p>
        </div>

        <form-group field="email" :errors="errors">
          <input type="email" v-model="email" class="form-control form-control-lg" placeholder="Email" autofocus>
        </form-group>

        <form-group field="password" :errors="errors">
          <input type="password" v-model="password" class="form-control form-control-lg" placeholder="Пароль">
        </form-group>

        <button class="btn btn-lg btn-primary btn-block" type="submit">Войти</button>

        <div class="d-flex justify-content-between mt-2">
          <router-link :to="{name: 'SignUp'}" class="text-primary">Создать аккаунт</router-link>
          <router-link :to="{name: 'PasswordRecovery'}" class="text-primary">Забыл пароль</router-link>
        </div>
      </form>
    </overlay>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import repository from '@/repository/UserRepository';
import Overlay from '@/views/common/Overlay.vue';

export default {
  name: 'SignIn',
  components: {
    Overlay,
    FormGroup,
  },
  data() {
    return {
      email: '',
      password: '',
      errors: {},
      loading: false,
    };
  },
  mounted() {
    repository.getMyProfile((data) => {
      this.loading = false;
      this.$store.commit('signin', data);
      this.$router.push({
        name: 'ProjectsList',
        params: { username: data.username },
      });
    });
  },
  methods: {
    signIn() {
      this.errors = {};
      this.loading = true;
      repository.signIn({
        email: this.email,
        password: this.password,
      }, () => {
        repository.getMyProfile((data) => {
          this.loading = false;
          this.$store.commit('signin', data);
          this.$router.push({
            name: 'ProjectsList',
            params: { username: data.username },
          });
        });
      }, (fields) => {
        this.loading = false;
        this.errors = fields;
      });
    },
  },
};
</script>

<style scoped>
  #app {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }
</style>
