<template>
<block-loader v-slot :condition="post">
  <router-link :to="{ name: 'Blog' }" class="btn btn-link my-3">Назад к блогу</router-link>
  <div class="d-flex justify-content-between">
    <h1>{{ post.title }}</h1>
    <div>{{ post.createdAt }}</div>
  </div>
  <div v-html="post.text" />
</block-loader>
</template>

<script>
import BlockLoader from '@/components/BlockLoader.vue';
import BlogRepository from '@/repository/BlogRepository';

export default {
  name: 'Post',
  components: { BlockLoader },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      post: null,
    };
  },
  mounted() {
    BlogRepository.view(this.id, (post) => {
      this.post = post;
    });
  },
};
</script>
