<template>
  <block-loader v-slot :condition="project !== null" class="row">
    <div class="col-12 pt-3">
      <h1><router-link :to="getProjectLink()">{{project.name}}</router-link> - участники</h1>
    </div>
    <div class="col-12 mb-3">
      <div class="card">
        <div class="card-header">
          Добавление участника
        </div>
        <div class="card-body">
          <add-member-form :project-id="project.id" @member-has-been-added="reloadMembers"/>
        </div>
      </div>
    </div>
    <div class="col-12">
      <h3>Участники проекта <span class="badge badge-light">{{total}}</span></h3>
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between"
            v-for="record in members" :key="record.user.id">
          <div>
            <div style="font-size: 1.1rem">
              <span class="text-success">{{record.user.username}}</span> - {{record.user.email}}
            </div>
            <div class="muted-link"
                 @click="removeMember(record.user.id)"
                 v-if="!isOwner(record.connection.role)">Удалить
            </div>
          </div>
          <div class="text-right">
            {{ roles[record.connection.role] }}
            <div class="text-muted"> {{ record.connection.createdAt | timestampToDate }}</div>
          </div>
        </li>
      </ul>

      <infinite-loading @infinite="infiniteHandler" :infiniteId="infiniteId">
        <div slot="spinner">Загрузка...</div>
        <div slot="no-more">
          <div>&nbsp;</div>
        </div>
        <div slot="no-results">
          <div>&nbsp;</div>
        </div>
      </infinite-loading>
      <div style="color: transparent">loading-fix</div>
    </div>
  </block-loader>
</template>

<script>
import InfiniteLoading from '@/components/lazy-load/InfiniteLoading.vue';
import ProjectMixin from '@/components/ProjectMixin';
import repository from '@/repository/ProjectRepository';
import BlockLoader from '@/components/BlockLoader.vue';
import UserRoleEnum from '@/components/enums/UserRoleEnum';
import AddMemberForm from './AddMemberForm.vue';

export default {
  name: 'Members',
  components: {
    AddMemberForm,
    BlockLoader,
    InfiniteLoading,
  },
  mixins: [ProjectMixin],
  data() {
    return {
      members: [],
      total: 0,
      page: 1,
      roles: {
        1: 'Владелец',
        2: 'Участник',
        3: 'Гость',
      },
      infiniteId: 1,
    };
  },
  methods: {
    isOwner(role) {
      return UserRoleEnum.isOwner(role);
    },
    infiniteHandler($state) {
      repository.listMembers(this.project.id, this.page, (response) => {
        this.total = response.total;
        if (response.records.length) {
          this.members.push(...response.records);
          this.page += 1;
          if (response.hasNext) {
            $state.loaded();
          } else {
            $state.complete();
          }
        }
      });
    },
    reloadMembers() {
      this.page = 1;
      this.members = [];
      this.infiniteId += 1;
    },
    removeMember(userId) {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm('Удалить участника?')) {
        return;
      }

      repository.removeMember(this.project.id, userId, () => {
        this.reloadMembers();
      });
    },
  },
};
</script>

<style scoped>

</style>
