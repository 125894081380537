let storageTheme = localStorage.getItem('theme');
if (!storageTheme) {
  storageTheme = 'light';
}

export default {
  state: {
    theme: storageTheme,
  },
  getters: {
    theme: state => state.theme,
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem('theme', theme);
    },
  },
};
