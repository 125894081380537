const localUser = JSON.parse(localStorage.getItem('user'));

export default {
  state: {
    user: localUser,
    status: '',
  },
  getters: {
    isGuest: state => state.user === null,
    user: state => state.user,
  },
  mutations: {
    signin(state, user) {
      state.status = 'success';
      state.user = user;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    signout: (state) => {
      state.status = 'logout';
      localStorage.removeItem('user');
      state.user = null;
    },
  },
};
