<template>
<div>
  <div class="row">
    <div class="col-12">
      <h1>Блог</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div v-for="post in posts" :key="post.id">
        <div class="d-flex justify-content-between">
          <router-link :to="{ name: 'Post', params: { id: post.id } }">
            <h2>{{ post.title }}</h2>
          </router-link>
          <div>{{ post.createdAt}}</div>
        </div>
        <div>
          <p>{{ post.announce }}</p>
          <router-link :to="{ name: 'Post', params: { id: post.id } }" class="btn btn-outline-success">
            Читать далее...
          </router-link>
        </div>
      </div>

      <infinite-loading @infinite="infiniteHandler">
        <template slot="no-results">
          <div class="d-flex flex-column align-items-center mt-5">
            Постов не найдено
          </div>
        </template>
      </infinite-loading>
    </div>
  </div>
</div>
</template>

<script>
import InfiniteLoading from '@/components/lazy-load/InfiniteLoading.vue';
import BlogRepository from '@/repository/BlogRepository';

export default {
  name: 'Blog',
  components: { InfiniteLoading },
  data() {
    return {
      posts: [],
      page: 1,
    };
  },
  methods: {
    infiniteHandler($state) {
      BlogRepository.list({ page: this.page }, (response) => {
        if (response.records.length) {
          this.posts.push(...response.records);
          this.page += 1;
          if (response.hasNext) {
            $state.loaded();
          } else {
            $state.complete();
          }
        }
      }, () => {
        $state.aborted();
      });
    },
  },
};
</script>
