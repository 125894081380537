let query = null;

export default {
  mounted() {
    query = JSON.stringify(this.$route.query);
  },
  watch: {
    $route() {
      if (typeof this.$options.watchQuery !== 'function') {
        return;
      }
      const queryString = JSON.stringify(this.$route.query);
      if (queryString !== query) {
        query = queryString;
        this.$options.watchQuery(this);
      }
    },
  },
};
