<template>
  <form @submit.prevent="addMember">
    <form-group :errors="errors" field="email">
      <label for="add-member-email">Email</label>
      <input type="email"
             v-model="email"
             class="form-control"
             id="add-member-email"
             aria-describedby="emailHelp"
             placeholder="your-friend@best-mail.com">
      <small id="emailHelp" class="form-text text-muted">Учасник должен быть зарегистрирован в Тулке</small>
    </form-group>

    <button type="submit" class="btn btn-primary">Добавить</button>
  </form>
</template>

<script>
import ProjectRepository from '@/repository/ProjectRepository';
import FormGroup from '@/components/FormGroup.vue';

export default {
  name: 'AddMemberForm',
  components: { FormGroup },
  props: ['projectId'],
  data() {
    return {
      email: '',
      errors: {},
    };
  },
  methods: {
    addMember() {
      ProjectRepository.addMember(this.projectId, this.email, () => {
        this.$emit('member-has-been-added');
        this.email = '';
        this.errors = {};
      }, (errors) => {
        console.log(errors);
        this.errors = errors;
      });
    },
  },
};
</script>

<style scoped>

</style>
