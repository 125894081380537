<template>
<div class="row">
  <div class="col-12">
    <h1>Мой профиль</h1>
  </div>

  <div class="col-12 col-lg-4">
    <h3>Аватар</h3>
    <img :src="model.avatar.replace(/{size}/g, '360x360i')" class="rounded-lg img-fluid">
    <avatar @loaded="loadProfile"/>
  </div>
  <div class="col-12 col-lg-4">
    <h3>Информация</h3>
    <div class="form-group">
      <label>Email</label>
      <input type="text" disabled v-model="model.email" class="form-control form-control-lg">
    </div>
    <h3>Изменение пароля</h3>
    <change-password :model="model"/>
  </div>
</div>
</template>

<script>
import repository from '@/repository/UserRepository';
import ChangePassword from './ChangePassword.vue';
import Avatar from './Avatar.vue';

export default {
  name: 'MyProfile',
  components: { Avatar, ChangePassword },
  data() {
    return {};
  },
  computed: {
    model() {
      return this.$store.getters.user;
    },
  },
  created() {
    this.loadProfile();
  },
  methods: {
    loadProfile() {
      repository.getMyProfile((data) => {
        this.$store.commit('signin', data);
      });
    },
  },
};
</script>
