<template>
<b-overlay :show="show" opacity="0.6">
  <slot />
  <template v-slot:overlay>
  <div>
    <loading />
  </div>
  </template>
</b-overlay>
</template>

<script>
import Vue from 'vue';
import { OverlayPlugin } from 'bootstrap-vue';
import Loading from '@/views/Loading.vue';

Vue.use(OverlayPlugin);

export default {
  name: 'Overlay',
  components: { Loading },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
