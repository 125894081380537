import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/modules/user';
import project from '@/store/modules/project';
import task from '@/store/modules/task';
import common from '@/store/modules/common';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    user,
    project,
    task,
  },
});
