<template>
<block-loader v-slot :condition="task" class="p-2">
  <div class="btn btn-info d-inline-block d-sm-none" @click="deselectTask">Закрыть</div>

  <overlay :show="loading">
    <!--  FIRST LINE -->
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
      <input type="text" class="position-fixed" style="left:-10000px; top:-10000px" :value="copyText" id="copy-text">
      <div class="d-flex align-items-center" style="min-height: 36px;">
        <div
          class="badge badge-dark pointer"
          style="font-size: 100%;"
          @click="copy('id')"
          id="task-id"
        >
          {{ getLiteralTaskId(task) }}
        </div>
        <b-tooltip target="task-id" triggers="hover">
          Скопировать номер задачи
        </b-tooltip>
        <font-awesome-icon
          @click="copy('link')"
          :icon="['far', 'copy']"
          class="m-1 ml-3 pointer"
          id="task-link"
        />
        <b-tooltip target="task-link" triggers="hover">
          Скопировать ссылку на задачу
        </b-tooltip>
        <font-awesome-icon
          @click="copy('linkAndTitle')"
          :icon="['far', 'copy']"
          class="m-1 ml-3 pointer"
          id="task-link-and-title"
        />
        <b-tooltip target="task-link-and-title" triggers="hover">
          Скопировать ссылку и заголовок
        </b-tooltip>
      </div>
      <div style="min-height: 36px;">
        <b-dropdown
          variant="link"
          toggle-tag="div"
          dropdown
          right
          class="pointer"
          :text="executorLabel"
          toggle-class="text-decoration-none pl-0"
        >
          <template v-slot:default>
            <b-dropdown-header>
              Исполнитель
            </b-dropdown-header>
            <b-dropdown-item
              :active="null === task.executorId"
              @click="setTaskField('executorId', null)"
            >
              Без исполнителя
            </b-dropdown-item>
            <b-dropdown-item
              v-for="member in members"
              :key="member.user.id"
              :active="member.user.id === task.executorId"
              @click="setTaskField('executorId', member.user.id)"
            >
              {{member.user.username}}
            </b-dropdown-item>
          </template>
        </b-dropdown>

        <b-dropdown
          variant="link"
          toggle-tag="div"
          dropdown
          right
          class="pointer"
          :text="priorityLabel"
          toggle-class="text-decoration-none"
        >
          <template v-slot:default>
            <b-dropdown-header>
              Приоритет
            </b-dropdown-header>
            <b-dropdown-item
              v-for="priority in priorities"
              :key="priority.id"
              :active="priority.id === task.priority"
              @click="setTaskField('priority', priority.id)"
            >
              {{priority.label}}
            </b-dropdown-item>
          </template>
        </b-dropdown>

        <b-dropdown
          variant="link"
          toggle-tag="div"
          dropdown
          right
          class="pointer"
          :no-caret="true"
          toggle-class="text-decoration-none"
        >
          <template v-slot:button-content>
            <font-awesome-icon
              :icon="['fas', 'ellipsis-v']"
            />
          </template>
          <template v-slot:default>
            <b-dropdown-item
              @click="confirmDeleteTask"
            >
              Удалить задачу
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
    </div>
    <!--  // FIRST LINE -->
    <hr>
    <!--  SECOND LINE -->
    <div class="mb-3 d-flex flex-column flex-md-row justify-content-between">
      <div>
        <div
          class="btn mr-3"
          style="cursor: default"
          :class="'btn-' + currentStatus.group.color"
          v-if="currentStatus"
        >
          {{currentStatus.label}}
        </div>
        <div class="d-inline-block" v-if="showStatusPanel">
          <div
            class="btn mr-1 pointer"
            v-for="status in availableStatuses"
            :key="status.id"
            @click="setTaskField('status', status.id)"
            :class="'btn-outline-' + status.group.color"
          >
            {{ status.label }}
          </div>
        </div>
        <div class="d-inline-block" v-else>
          <b-dropdown
            variant="outline-success"
            toggle-tag="div"
            dropdown
            right
            class="pointer"
            text="Изменить статус"
            toggle-class="text-decoration-none"
          >
            <template v-slot:default>
            <b-dropdown-item
              v-for="status in $store.getters['task/statuses'].statuses"
              :key="status.id"
              :active="status.id === task.status"
              @click="changeStatus(status.id)"
            >
              {{status.label}}
            </b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
        <div class="btn btn-link">
          <font-awesome-icon
            @click="showStatusPanel = !showStatusPanel"
            :icon="['fas', 'pencil-alt']"
            title="Изменить статус"
            class="pointer"/>
        </div>
      </div>
      <div>
        <div style="font-size: 23px" v-if="settings['task.timerEnabled']">
          {{ taskTimer }}
        </div>
      </div>
    </div>
    <!--  // SECOND LINE -->
    <hr>
    <!--  THIRD LINE -->
    <div>
      <div class="mb-3">
        <input
          v-if="editableField === 'title'"
          type="text"
          class="title"
          v-model="task.title"
          ref="titleInput"
          @keydown.enter="updateTask"
          @blur="updateTask"
          @keydown.esc="cancelEditing"
          @keydown.tab.prevent="tabToDescription"
        >
        <div v-else>
          <div class="title text-muted" v-if="task.title === ''" @click="startEditingInput('title')">
            Заголовок...
          </div>
          <div class="title" v-else @click="startEditingInput('title')">{{ task.title }}</div>
        </div>
      </div>

      <div v-if="model === 'LargeTask'">
        <div v-if="editableField === 'description'">
            <textarea
              type="text"
              class="form-control mb-1"
              ref="descriptionInput"
              v-model="task.description"
              @keydown.esc="cancelEditing"
              rows="10"
            ></textarea>
          <div class="btn btn-success pointer mr-2" @click="updateTask">Сохранить</div>
          <div class="btn btn-secondary pointer" @click="cancelEditing">Отменить</div>
        </div>
        <div v-else>
          <p class="text-muted"
             v-if="task.description === ''"
             @click.prevent="startEditingInput('description')"
          >
            Описание...
          </p>
          <div v-else @click.prevent="startEditingInput('description')">
            <vue-simple-markdown :source="task.description" :postrender="postRender" />
          </div>
        </div>

        <hr>

        <div>
          <comments :task="task"/>
        </div>

        <hr>

        <div class="text-right text-muted">
          Автор: {{ task.creator.username }}<br>
          Создана: {{ task.createdAt }}<br>
          Изменена: {{ task.updatedAt }}<br>
        </div>
      </div>
      <loading v-else />
    </div>
    <!--  // THIRD LINE -->
  </overlay>
</block-loader>
</template>

<script>
/* eslint-disable no-restricted-syntax,guard-for-in */
import Vue from 'vue';
import VueSimpleMarkdown from 'vue-simple-markdown';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BDropdown, BTooltip, ModalPlugin } from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faPencilAlt, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import BlockLoader from '@/components/BlockLoader.vue';
import TaskMixin from '@/components/TaskMixin';
import TaskRepository from '@/repository/TaskRepository';
import Loading from '@/views/Loading.vue';
import Overlay from '@/views/common/Overlay.vue';
import Comments from '@/actions/task/view/Comments.vue';
import 'vue-simple-markdown/dist/vue-simple-markdown.css';

Vue.use(ModalPlugin);
Vue.use(VueSimpleMarkdown);
library.add(faCheckSquare);
library.add(faPencilAlt);
library.add(faCopy);
library.add(faEllipsisV);

const priorities = [
  { id: 1, label: 'Низкий' },
  { id: 2, label: 'Средний' },
  { id: 3, label: 'Высокий' },
  { id: 4, label: 'Кошмар' },
];

export default {
  name: 'TaskView',
  components: {
    Overlay,
    Loading,
    BlockLoader,
    FontAwesomeIcon,
    BDropdown,
    BTooltip,
    Comments,
  },
  mixins: [
    TaskMixin,
  ],
  props: {
    project: Object,
    settings: Object,
    smallTask: Object,
    members: Array,
  },
  data() {
    return {
      task: null,
      model: 'NoTask',
      editableField: null,
      priorities,
      copyText: '',
      originalValue: null,
      loading: false,
      showStatusPanel: true,
      time: 0,
    };
  },
  computed: {
    priorityLabel() {
      for (const i in priorities) {
        if (this.task.priority === priorities[i].id) {
          return priorities[i].label;
        }
      }

      return 'Приортитет';
    },
    executorLabel() {
      for (const i in this.members) {
        if (this.members[i].user.id === this.task.executorId) {
          return this.members[i].user.username;
        }
      }

      return 'Исполнителя нет';
    },
    availableStatuses() {
      const statuses = this.$store.getters['task/statuses'];
      if (statuses === null) {
        return [];
      }
      const ids = statuses.graph[this.task.status];
      const result = [];
      for (const i in ids) {
        // eslint-disable-next-line
        result.push(statuses.statuses[ids[i]]);
      }

      return result;
    },
    currentStatus() {
      const statuses = this.$store.getters['task/statuses'];
      if (statuses === null) {
        return null;
      }

      return this.$store.getters['task/statuses'].statuses[this.task.status];
    },
    taskTimer() {
      if (this.task === null || this.model !== 'LargeTask') {
        return '--:--';
      }
      let { duration } = this.task.timer;

      if (this.task.timer.activeTimer !== null) {
        duration += this.time - this.task.timer.activeTimer.startedAt;
      }

      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration - (hours * 3600)) / 60);
      let seconds = duration - (hours * 3600) - (minutes * 60);
      seconds = Math.round(seconds * 100) / 100;

      let result = `${hours < 10 ? `0${hours}` : hours}:`;
      result += (minutes < 10 ? `0${minutes}` : minutes);
      result += `:${seconds < 10 ? `0${seconds}` : seconds}`;

      return result;
    },
  },
  watch: {
    smallTask() {
      this.editableField = null;
      this.task = this.smallTask;
      this.model = 'SmallTask';
      this.loadLargeTask();
      if (this.task.title === '') {
        this.$nextTick(() => {
          this.startEditingInput('title');
        });
      }
    },
  },
  created() {
    if (typeof this.$route.params.taskLiteralId !== 'undefined') {
      TaskRepository.viewByLiteralId(this.$route.params.taskLiteralId, this.project.id, (response) => {
        this.task = response;
        this.model = 'LargeTask';
        this.$emit('selectTask', this.task);
      });
    }
  },
  mounted() {
    this.time = Math.floor(Date.now() / 1000);
    setInterval(() => {
      this.time = Math.floor(Date.now() / 1000);
    }, 1000);
    window.goToHashTagEvent2 = (hashTag) => {
      const event = new CustomEvent('goToHashTagEvent', { detail: hashTag });
      window.dispatchEvent(event);
    };
    // window.goToHashTagEvent = document.createEvent('Event');
    // window.goToHashTagEvent.initEvent('goToHashTagEvent', true, true);
    window.addEventListener('goToHashTagEvent', this.goToHashTag);
  },
  beforeDestroy() {
    window.removeEventListener('goToHashTagEvent', this.goToHashTag);
  },
  methods: {
    postRender(html) {
      const expression = /(#[\wа-яёй\d]+)/ig;
      return html.replace(
        expression,
        '<span '
        + 'class="a" '
        + 'onclick="window.goToHashTagEvent2(\'$1\');event.stopPropagation();return false;">$1</span>',
      );
    },
    goToHashTag(event) {
      const pattern = /^(#[\wа-яёй\d]+)$/ig;
      if (!pattern.test(event.detail)) {
        return;
      }
      this.$router.push({
        name: 'TaskIndex',
        params: { taskLiteralId: this.getLiteralTaskId(this.task) },
        query: { query: event.detail },
      });
    },
    loadLargeTask() {
      TaskRepository.view(this.task.id, (response) => {
        if (response.id !== this.task.id) {
          return;
        }
        this.task = response;
        this.model = 'LargeTask';
      });
    },
    deselectTask() {
      this.$emit('deselectTask');
      this.$router.push({
        name: 'TaskIndex',
        query: this.$route.query,
      });
    },
    setTaskField(field, value) {
      this.task[field] = value;
      this.updateTask();
    },
    changeStatus(id) {
      this.setTaskField('status', id);
      this.showStatusPanel = true;
      this.processTimer(id === 3);
    },
    processTimer(isPlay) {
      if (isPlay) {
        this.$set(this.task.timer, 'activeTimer', {
          startedAt: this.time,
        });
      } else {
        if (this.task.timer.activeTimer === null) {
          return;
        }
        this.$set(
          this.task.timer,
          'duration',
          this.task.timer.duration + (this.time - this.task.timer.activeTimer.startedAt),
        );
        this.$set(this.task.timer, 'activeTimer', null);
      }
    },
    startEditingInput(field) {
      this.originalValue = this.task[field];
      this.editableField = field;
      if (field === 'title' || field === 'description') {
        this.$nextTick(() => {
          this.$refs[`${field}Input`].focus();
        });
      }
    },
    cancelEditing() {
      this.$set(this.task, this.editableField, this.originalValue);
      this.editableField = null;
      this.originalValue = null;
    },
    updateTask() {
      this.loading = true;
      TaskRepository.update(this.task, () => {
        this.$emit('selectTask', this.task);
        this.loading = false;
      });
      this.editableField = null;
    },
    copy(type) {
      this.copyText = '';
      if (type === 'id') this.copyText = this.getLiteralTaskId(this.task);
      if (type === 'link' || type === 'linkAndTitle') {
        this.copyText = window.location.origin + this.$router.resolve({
          name: 'TaskIndex',
          params: { taskLiteralId: this.getLiteralTaskId(this.task) },
        }).href;
        if (type === 'linkAndTitle') {
          this.copyText += ` ${this.task.title}`;
        }
      }

      this.$nextTick(() => {
        const copyText = document.getElementById('copy-text');
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
      });
    },
    tabToDescription() {
      this.updateTask();
      this.$nextTick(() => {
        this.startEditingInput('description');
      });
    },
    confirmDeleteTask() {
      this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить задачу?', {
        cancelTitle: 'Нет',
        okTitle: 'Да',
      })
        .then((value) => {
          if (value) {
            this.deleteTask();
          }
        });
    },
    deleteTask() {
      this.loading = true;
      const { id } = this.task;
      TaskRepository.deleteTask(id, () => {
        this.$router.push({
          name: 'TaskIndex',
          query: this.$route.query,
        });
        this.$emit('deleteTask', id);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  width: 100%;
  font-size: 1.5rem;
  margin: 0;
  outline: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem 0.375rem 0;

  &:hover {
    background-color: var(--light);
    cursor: pointer;
  }
}

input.title {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;

  &:hover {
    background-color: transparent;
  }
}
</style>
