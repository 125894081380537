<template>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-12 col-lg-6">
        <h1 v-if="model">Редактирование проекта</h1>
        <h1 v-else>Новый проект</h1>
        <form-group :errors="errors" field="name">
          <label for="create-project-name">Название</label>
          <input type="text"
                 v-model="name"
                 class="form-control"
                 id="create-project-name"
                 aria-describedby="nameHelp"
                 maxlength="30"
                 placeholder="Суперпроект">
        </form-group>

        <form-group :errors="errors" field="description">
          <label for="create-project-description">Описание</label>
          <textarea
            v-model="description"
            class="form-control"
            id="create-project-description"
            aria-describedby="descriptionHelp"
            maxlength="100"
            placeholder="Суперпроект делает суперштуки"></textarea>
        </form-group>

        <form-group :errors="errors" field="slug">
          <label for="settings-task-literal">Адрес проекта</label>
          <div class="text-danger" v-if="model">
            <b>Внимание!</b> Изменив это поле вы измените адреса всех страниц. Из-за этого перестанут работать все
            внешние ссылки на проект! Убедитесь, что вам действительно необходимо изменить это поле!
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon3">https://toolka.net/{{username}}/</span>
            </div>
            <input type="text"
                   v-model="slug"
                   class="form-control"
                   id="settings-task-literal"
                   maxlength="30"
                   aria-describedby="settings-task-literal-describedby"
                   placeholder="superproject">
          </div>
          <small id="settings-task-literal-describedby" class="form-text text-muted">
            Только <b class="text-success">a-z</b>, <b class="text-success">0-9</b>,
            <b class="text-success">-</b> и <b class="text-success">_</b>.
            Адрес получится таким: <b>https://toolka.net/{{username}}/{{slug}}</b> если что.
          </small>
        </form-group>

        <form-group :errors="errors" field="color">
          <label>Цвет</label>
          <div class="btn-group d-block">
            <div class="btn btn-secondary"
                 v-for="(colorItem, key) in colors"
                 :key="key"
                 :class="{ active: colorItem.hex === color }"
                 @click="color = colorItem.hex"
            >{{colorItem.label}}
            </div>
          </div>
          <small class="form-text text-muted">Для цветовой дифференциации суперпроектов</small>
        </form-group>

        <button type="submit" class="btn btn-success">{{ model ? 'Сохранить' : 'Создать'}}</button>
      </div>
    </div>
  </form>

</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import ProjectRepository from '@/repository/ProjectRepository';

export default {
  name: 'CreateProject',
  components: { FormGroup },
  props: ['model'],
  data() {
    return {
      name: '',
      description: '',
      slug: '',
      color: '',
      colors: [
        {
          hex: 'ff0000',
          label: 'Красный',
        },
        {
          hex: '00ff00',
          label: 'Зеленый',
        },
        {
          hex: '0000ff',
          label: 'Синий',
        },
        {
          hex: 'ffffff',
          label: 'Белый',
        },
        {
          hex: '000000',
          label: 'Черный',
        },
      ],
      errors: {},
    };
  },
  computed: {
    username() {
      return this.$store.getters.user.username;
    },
  },
  created() {
    if (this.model) {
      this.name = this.model.name;
      this.description = this.model.description;
      this.slug = this.model.slug;
      this.color = this.model.color;
    }
  },
  methods: {
    submit() {
      const data = {
        name: this.name,
        description: this.description,
        slug: this.slug,
        color: this.color,
      };
      let method = 'create';

      if (this.model) {
        data.projectId = this.model.id;
        method = 'update';
      }
      ProjectRepository[method](data, () => {
        this.$store.commit('project/breakProjects');
        this.$router.push({
          name: 'ProjectView',
          params: {
            username: this.$store.getters.user.username,
            projectname: this.slug,
          },
        });
      },
      (errors) => {
        this.errors = errors;
      });
    },
  },
};
</script>

<style scoped>

</style>
