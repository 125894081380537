<template>
<div>
  <h4>Комментарии</h4>

  <form-group :errors="errors" field="text">
    <label for="create-project-description">Добавить комментарий</label>
    <textarea
      v-model="text"
      class="form-control"
      id="create-project-description"
      aria-describedby="descriptionHelp"
      maxlength="1000"></textarea>
  </form-group>
  <div class="btn btn-success" @click="addComment()">Добавить</div>

  <div v-for="(comment, index) in comments" :key="comment.id" class="d-flex my-2 bg-light comment p-2">
    <div>
      <img :src="comment.user.avatar.replace(/{size}/g, '90x90i')" class="rounded-lg" style="width: 40px;">
    </div>
    <div class="flex-grow-1 pl-2">
      <div class="d-flex justify-content-between">
        <div class="text-primary">
          <b>{{ comment.user.username }}</b>
          <span class="text-muted ml-3">{{ comment.createdAt | timestampToDateTime }}</span>
        </div>
        <div class="text-muted">
          <font-awesome-icon
            @click="showUpdateModal(index)"
            :icon="['fas', 'pencil-alt']"
            class="m-1 mr-3 pointer"
            title="Изменить комментарий"
          />
          <font-awesome-icon
            @click="deleteComment(index)"
            :icon="['fas', 'trash-alt']"
            class="m-1 pointer"
            title="Удалить комментарий"
          />
        </div>
      </div>
      <p style="white-space: pre-wrap" class="mb-1">{{ comment.text }}</p>
    </div>
  </div>
  <b-pagination
    v-model="page"
    v-show="total > pageSize"
    :total-rows="total"
    :per-page="pageSize"
    size="sm"
    aria-controls="my-table"
    @change="selectPage"
  ></b-pagination>

  <b-modal
    v-model="updateModalIsVisible"
    centered
    @ok="updateComment"
    id="update-modal"
    hide-footer
    title="Изменить комментарий"
  >
    <form-group :errors="updateErrors" field="text">
      <textarea
        v-model="updateText"
        class="form-control"
        id="update-comment"
        aria-describedby="descriptionHelp"
        maxlength="1000"></textarea>
    </form-group>

    <div class="d-flex justify-content-end">
      <div class="btn btn-secondary mr-2" @click="$bvModal.hide('update-modal')">Отмена</div>
      <div class="btn btn-success" @click="updateComment()">Сохранить</div>
    </div>
  </b-modal>
</div>
</template>

<script>
import Vue from 'vue';
import { BPagination, ModalPlugin } from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import FormGroup from '@/components/FormGroup.vue';
import TaskCommentRepository from '@/repository/TaskCommentRepository';

library.add(faTrashAlt, faPencilAlt);
Vue.use(ModalPlugin);

export default {
  name: 'Comments',
  components: {
    FormGroup,
    FontAwesomeIcon,
    'b-pagination': BPagination,
  },
  props: {
    task: Object,
  },
  data() {
    return {
      comments: [],
      text: '',
      page: 1,
      pageSize: 3,
      total: 1,
      errors: {},
      updateModalIsVisible: false,
      updateIndex: 0,
      updateText: '',
      updateErrors: {},
    };
  },
  watch: {
    task() {
      this.page = 1;
      this.text = '';
      this.errors = {};
      this.loadComments();
    },
  },
  created() {
    this.loadComments();
  },
  methods: {
    loadComments() {
      TaskCommentRepository.list({
        taskId: this.task.id, pageSize: this.pageSize, page: this.page, sortOrder: '-createdAt',
      }, (response) => {
        this.comments = response.records;
        this.total = response.total;
      });
    },
    addComment() {
      TaskCommentRepository.add(this.task.id, this.text, () => {
        this.loadComments();
        this.text = '';
        this.errors = {};
      }, (fields) => {
        this.errors = fields;
      });
    },
    selectPage(page) {
      this.page = page;
      this.loadComments();
    },
    deleteComment(index) {
      this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить этот комментарий?', {
        okTitle: 'Удалить',
        cancelTitle: 'Отменить',
        centered: true,
      })
        .then((value) => {
          if (value === true) {
            TaskCommentRepository.remove(this.comments[index].id, () => {
              this.comments.splice(index, 1);
            });
          }
        })
        .catch(() => {
        });
    },
    showUpdateModal(index) {
      this.updateIndex = index;
      this.updateModalIsVisible = true;
      this.updateText = this.comments[index].text;
      this.updateErrors = {};
    },
    updateComment() {
      const index = this.updateIndex;
      TaskCommentRepository.update(this.comments[index].id, this.updateText, () => {
        if (this.comments[index].id === this.updateText) {
          this.$bvModal.hide('update-modal');
          return;
        }
        this.$set(this.comments[index], 'text', this.updateText);
        this.updateModalIsVisible = false;
        this.updateErrors = {};
        this.$bvModal.hide('update-modal');
      }, (fields) => {
        this.updateErrors = fields;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.comment {
  border-left: 4px solid var(--primary);

  &:hover {
    border-left: 4px solid var(--secondary);
  }
}
</style>
