/* eslint-disable guard-for-in,no-restricted-syntax */
export default {
  props: ['username', 'projectname'],
  data() {
    return {
      projectReadyStack: [],
    };
  },
  computed: {
    slug() {
      return `${this.username}/${this.projectname}`;
    },
    project() {
      if (typeof this.$store.getters['project/projects'][this.slug] === 'undefined') {
        return null;
      }

      return this.$store.getters['project/projects'][this.slug];
    },
    settings() {
      if (this.project === null) {
        return null;
      }
      if (typeof this.$store.getters['project/settings'][this.project.id] === 'undefined') {
        return null;
      }

      return this.$store.getters['project/settings'][this.project.id];
    },
  },
  beforeMount() {
    if (this.project !== null) {
      if (typeof this.projectLoaded !== 'undefined') {
        this.projectLoaded();
      }
      this.callProjectStack();
      return;
    }
    this.$store.dispatch('project/loadProjects', this.slug).then(() => {
      if (typeof this.projectLoaded !== 'undefined') {
        this.projectLoaded();
      }
      this.callProjectStack();
    });
  },
  methods: {
    getProjectLink() {
      return {
        name: 'ProjectView',
        params: {
          username: this.username,
          projectname: this.projectname,
        },
      };
    },
    whenProjectReady(callback) {
      if (this.project !== null) {
        callback(this.project);
        return;
      }
      if (typeof this.projectReadyStack === 'undefined') {
        this.projectReadyStack = [];
      }
      this.projectReadyStack.push(callback);
    },
    callProjectStack() {
      for (const i in this.projectReadyStack) {
        this.projectReadyStack[i](this.project);
      }
    },
  },
};
