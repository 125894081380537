<template>
<div>
  <app-header/>
  <main class="flex-grow-1 overflow-hidden" data-layout="wide">
    <slot/>
  </main>
</div>
</template>

<script>
import AppHeader from '../AppHeader.vue';

export default {
  name: 'WideWithoutFooterLayout',
  components: {
    AppHeader,
  },
};
</script>

<style scoped>

</style>
