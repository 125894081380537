<template>
  <block-loader v-slot :condition="project !== null" class="row">
    <div class="col-12 pt-3">
      <h1>
        <router-link :to="getProjectLink()">{{project.name}}</router-link>
        - настройки
      </h1>
      <div class="alert alert-success" v-if="alert">{{alert}}</div>
    </div>
    <form @submit.prevent="submit">
      <div class="col-12">
        <h3>Задачи</h3>
        <form-group :errors="errors" field="model.task_literal">
          <label for="settings-task-literal">Префикс номера задач</label>
          <input type="text"
                 v-model="model.task_literal"
                 name="task.literal"
                 class="form-control"
                 id="settings-task-literal"
                 aria-describedby="settings-task-literal-describedby">
          <small id="settings-task-literal-describedby" class="form-text text-muted">
            Например если префикс "TOOLKA", то номер задачи будет отображаться как "TOOLKA-108"
          </small>
        </form-group>

        <form-group :errors="errors" field="model.task_timerEnabled">
          <label for="settings-task-literal">Таймер задач</label>
          <div class="btn-group d-block" role="group" aria-label="Таймер задач">
            <button type="button"
                    class="btn btn-secondary"
                    :class="{ active: model.task_timerEnabled}"
                    @click="model.task_timerEnabled = true">
              Включен
            </button>
            <button type="button"
                    class="btn btn-secondary"
                    :class="{ active: !model.task_timerEnabled}"
                    @click="model.task_timerEnabled = false">
              Выключен
            </button>
          </div>
          <input type="hidden" name="task.timerEnabled" v-model="model.task_timerEnabled">
          <small id="settings-task-timerEnabled-describedby" class="form-text text-muted">
            Включает таймер подсчета времени выполнения задачи
          </small>
        </form-group>

        <button type="submit" class="btn btn-success">Сохранить</button>
      </div>
    </form>
  </block-loader>
</template>

<script>
import ProjectRepository from '@/repository/ProjectRepository';
import ProjectMixin from '@/components/ProjectMixin';
import BlockLoader from '@/components/BlockLoader.vue';
import FormGroup from '@/components/FormGroup.vue';

export default {
  name: 'Settings',
  components: {
    FormGroup,
    BlockLoader,
  },
  mixins: [ProjectMixin],
  data() {
    return {
      errors: {},
      model: {
        task_literal: '',
        task_timerEnabled: true,
      },
      alert: '',
    };
  },
  mounted() {
  },
  methods: {
    projectLoaded() {
      this.load();
    },
    load() {
      ProjectRepository.getSettingsList(this.project.id, (response) => {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const key in response) {
          const jsKey = key.replace(/\./g, '_');
          if (typeof this.model[jsKey] !== 'undefined') {
            this.$set(this.model, jsKey, response[key]);
          }
        }
      });
    },
    submit() {
      const data = {
        projectId: this.project.id,
      };
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in this.model) {
        const phpKey = key.replace(/_/g, '.');
        data[phpKey] = this.model[key];
      }
      ProjectRepository.updateSettings(data, () => {
        this.alert = 'Настройки успешно сохранены';
        setTimeout(() => { this.alert = ''; }, 5000);
        this.$store.dispatch('project/loadSettings', this.project.id);
      }, (fields) => {
        this.errors = fields;
      });
    },
  },
};
</script>

<style scoped>

</style>
