<template>
<div class="d-flex flex-column h-100">
  <app-header/>
  <main class="flex-shrink-0" data-layout="wide">
    <slot/>
  </main>
  <app-footer/>
</div>
</template>

<script>
import AppFooter from '../AppFooter.vue';
import AppHeader from '../AppHeader.vue';

export default {
  name: 'WideLayout',
  components: {
    AppFooter,
    AppHeader,
  },
};
</script>
