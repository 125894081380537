<template>
  <div
    @click="toggle"
    class="btn btn-light btn-sm pointer"
    style="border-radius: 0 .2rem .2rem 0;"
  >
    <font-awesome-icon :icon="['fas', screen ? 'angle-double-left' : 'angle-double-right']"/>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

library.add(faAngleDoubleLeft);
library.add(faAngleDoubleRight);

export default {
  name: 'ScreenToggle',
  components: {
    FontAwesomeIcon,
  },
  computed: {
    screen() { return this.$store.getters['task/screen']; },
  },
  methods: {
    toggle() {
      this.$store.commit('task/toggleScreen');
    },
  },
};
</script>

<style scoped>

</style>
