<template>
<div ref="lazyLoadRef">
  <slot name="no-more" v-if="slotState === 'no-more'"><div>&nbsp;</div></slot>
  <slot name="spinner" v-if="slotState === 'spinner'"><div class="text-center my-2"><loading /></div></slot>
  <slot name="no-results" v-if="slotState === 'no-results'"><div>Ничего не найдено</div></slot>
  <slot name="aborted" v-if="slotState === 'aborted'"><div>Произошла ошибка, список не загружен.</div></slot>
</div>
</template>

<script>
import OnVisibleMixin from '@/components/lazy-load/OnVisibleMixin';
import Loading from '@/views/Loading.vue';

const states = {
  standby: 1,
  wait: 2,
  end: 3,
};

export default {
  name: 'InfiniteLoading',
  components: { Loading },
  mixins: [OnVisibleMixin],
  props: ['infiniteId'],
  data() {
    return {
      somethingLoaded: false,
      state: states.standby,
      slotState: 'spinner',
      isInfiniteLazyLoad: true,
    };
  },
  serverPrefetch() {
    return new Promise((resolve, reject) => {
      this.$emit('infinite', {
        loaded: () => { resolve(); },
        aborted: () => { reject(); },
        complete: () => { resolve(); },
      });
    });
  },
  watch: {
    infiniteId() {
      this.state = states.wait;
      this.slotState = 'spinner';
      this.somethingLoaded = false;
      this.$emit('infinite', this.getCallback());
    },
  },
  methods: {
    onVisible() {
      if (this.state === states.end || this.state === states.wait) {
        return;
      }

      this.state = states.wait;
      this.slotState = 'spinner';
      this.$emit('infinite', this.getCallback());
    },
    getCallback() {
      return {
        complete: this.complete,
        loaded: this.loaded,
        aborted: this.aborted,
      };
    },
    complete() {
      this.state = states.end;
      this.slotState = this.somethingLoaded ? 'no-more' : 'no-results';
    },
    loaded() {
      this.state = states.standby;
      this.somethingLoaded = true;
      if (!this.$isServer && this.isElementVisible()) {
        this.onVisible();
      }
    },
    aborted() {
      this.state = states.end;
      this.slotState = 'aborted';
    },
  },
};
</script>
