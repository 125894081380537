<template>
<div>
  <div class="hero">
    <div class="container">
      <div class="subheading">Toolka</div>
      <div class="heading">Toolbelt on your ass</div>
      <div>
        <router-link :to="{ name: 'SignIn' }" class="btn btn-success btn-lg mb-3" style="font-size: 30px;">
          Войти
        </router-link>
        <br>
        <router-link :to="{ name: 'NoApp' }" class="mr-3">
          <img src="/img/logos/gp.png" style="height: 46px;">
        </router-link>
        <router-link :to="{ name: 'NoApp' }">
          <img src="/img/logos/as.png" style="height: 46px;">
        </router-link>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 py-5">
        <h4>Приветствуем вас в вашем карманном наборе инструментов для успешной и ненапряжной работы!</h4>
        <div class="row">
          <div class="col-12 col-md-6 p-3">
            <p>Совсем скоро вы сможете с помощью нашего сервиса полностью организовать работу со своей командой,
              составить свой собственный набор инструментов, помогающий вам в работе.</p>
          </div>
          <div class="col-12 col-md-6 p-3">
            <p>Предполагается широкий спектр инструментов для разработчиков, для маркетингового отдела, для
              менеджеров и руководителей, и конечно для удобного взаимодействия внутри коллектива.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <img src="/img/site/preview1.jpg" class="img-fluid">
      </div>
      <div class="col-12 col-md-6 d-flex align-items-center">
        <div>
        <h4>Хотите оценить качество - переходите в наш ТАСКТРЕКЕР и начинайте тестировать!</h4>
        <p>Это первый инструмент в вашем наборе, мы начали с него, потому что коммуникации сложнее всего организовать,
          а организовать безупречно, так чтобы всей команде было удобно, понятно и помогало продуктивно работать -
          практически невозможно.
          Да, мы замахнулись на невозможное. Помогите нам
          довести инструмент не просто до ума, а до лоска, блеска и звона. Тогда ваш безупречный тасктрекер займет
          свое почетное место в наборе,
          и вам будет приятно начинать каждый рабочий день, не зависимо от количества и объема задач.</p>
        </div>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-12 col-md-6">
        <h4>А что будет дальше?</h4>
        <p>
          Какие инструменты планируется добавить совсем скоро, а какие в третью и четвертую
          очередь?<br>
          Всему свое время, планов и инструментов очень много, мы будем анонсировать все изменения и дополнения,
          чтобы ничего не пропустить читайте наш
          <router-link :to="{ name: 'Blog' }">БЛОГ</router-link>
          . И конечно, мы ждем
          ваших отзывов, предложений, мыслей, ведь вы с
          нашей помощью собираете удобный для работы набор инстументов. А мы с вашей помощью делаем его еще удобнее.
        </p>
      </div>
      <div class="col-12 col-md-6">
        <h4>Блог разработчика</h4>
        <div v-for="post in posts" :key="post.id">
          <div class="d-flex justify-content-between">
            <router-link :to="{ name: 'Post', params: { id: post.id } }">
              <h5>{{ post.title }}</h5>
            </router-link>
            <div>{{ post.createdAt}}</div>
          </div>
          <div>
            <p>{{ post.announce }}</p>
            <router-link :to="{ name: 'Post', params: { id: post.id } }" class="btn btn-sm btn-outline-success">
              Читать далее...
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import BlogRepository from '@/repository/BlogRepository';

export default {
  name: 'MainPage',
  data() {
    return {
      posts: [],
    };
  },
  mounted() {
    BlogRepository.list({ page: 1, limit: 3 }, (response) => {
      if (response.records.length) {
        this.posts.push(...response.records);
      }
    });
  },
};
</script>

<style scoped>
.hero {
  text-align: center;
  color: #fff;
  padding-top: 10.5rem;
  padding-bottom: 6rem;
  background-image: url('/img/site/banner.jpg');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

@media (min-width: 768px) {
  .hero {
    padding-top: 17rem;
    padding-bottom: 12.5rem;
  }
}

.subheading {
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.5rem;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .subheading {
    font-size: 2.25rem;
    font-style: italic;
    line-height: 2.25rem;
    margin-bottom: 2rem;
  }
}

.heading {
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 3.25rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .heading {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 4.5rem;
    margin-bottom: 4rem;
  }
}
</style>
