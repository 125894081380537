import http from './Http';

function list(filters, onSuccess) {
  http.get('/task-comment/list', filters, onSuccess);
}

function add(id, text, onSuccess, onNotValid) {
  http.post('/task-comment/create', { taskId: id, text }, onSuccess, onNotValid);
}

function update(id, text, onSuccess, onNotValid) {
  http.post('/task-comment/update', { id, text }, onSuccess, onNotValid);
}

function remove(id, onSuccess) {
  http.delete('/task-comment/delete', { id }, onSuccess);
}

export default {
  list,
  add,
  remove,
  update,
};
