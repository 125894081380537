<template>
<block-loader v-slot :condition="project !== null">
  <create-project :model="project"></create-project>
</block-loader>
</template>

<script>
import ProjectMixin from '@/components/ProjectMixin';
import BlockLoader from '@/components/BlockLoader.vue';
import CreateProject from './CreateProject.vue';

export default {
  name: 'UpdateProject',
  components: { CreateProject, BlockLoader },
  mixins: [ProjectMixin],
};
</script>

<style scoped>

</style>
