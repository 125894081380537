var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('block-loader',{staticClass:"row",attrs:{"condition":_vm.dashboard && _vm.project},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"col-12 pt-3 d-flex justify-content-between"},[_c('h1',[_vm._v(_vm._s(_vm.project.name))]),_c('div',[_c('router-link',{staticClass:"btn btn-primary d-block",attrs:{"to":{
          name: 'UpdateProject',
          params: {
            username: _vm.username,
            projectname: _vm.projectname,
          },
        }}},[_vm._v(" Редактировать ")])],1)]),_c('div',{staticClass:"col-12"},[_c('p',[_vm._v(_vm._s(_vm.project.description))])]),_c('div',{staticClass:"col-12 col-lg-4 p-2"},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body",staticStyle:{"padding-bottom":"50px"}},[_c('h5',{staticClass:"card-title"},[_vm._v("Задачи")]),_vm._v(" Всего: "+_vm._s(_vm.dashboard.tasksQuantity)+" "),_c('p',{staticClass:"card-text"},[_vm._v("Модуль задач.")]),_c('router-link',{staticClass:"btn btn-primary btn-right-bottom",attrs:{"to":{
        name: 'TaskIndex',
        params: {
          username: _vm.username,
          projectname: _vm.projectname,
        },
      }}},[_vm._v("Перейти к задачам")])],1)])]),_c('div',{staticClass:"col-12 col-lg-4 p-2"},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body",staticStyle:{"padding-bottom":"50px"}},[_c('h5',{staticClass:"card-title"},[_vm._v("Участники")]),_vm._v(" Всего: "+_vm._s(_vm.dashboard.membersQuantity)+" "),_c('p',{staticClass:"card-text"},[_vm._v("Управляйте доступом людей к проекту.")]),_c('router-link',{staticClass:"btn btn-primary btn-right-bottom",attrs:{"to":{
        name: 'Members',
        params: {
          username: _vm.username,
          projectname: _vm.projectname,
        },
      }}},[_vm._v("Перейти к списку участников")])],1)])]),_c('div',{staticClass:"col-12 col-lg-4 p-2"},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body",staticStyle:{"padding-bottom":"50px"}},[_c('h5',{staticClass:"card-title"},[_vm._v("Настройки")]),_c('p',{staticClass:"card-text"},[_vm._v("Настройки необходимые для кастомизации проекта.")]),_c('router-link',{staticClass:"btn btn-primary btn-right-bottom",attrs:{"to":{
        name: 'Settings',
        params: {
          username: _vm.username,
          projectname: _vm.projectname,
        },
      }}},[_vm._v("Перейти к настройкам")])],1)])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }