import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import SignIn from '../actions/user/SignIn.vue';
import SignUp from '../actions/user/SignUp.vue';
import PasswordRecovery from '../actions/user/PasswordRecovery.vue';
import MyProfile from '../actions/user/profile/MyProfile.vue';
import ProjectsList from '../actions/project/ProjectsList.vue';
import ProjectView from '../actions/project/ProjectView.vue';
import Members from '../actions/project/Members.vue';
import Settings from '../actions/project/Settings.vue';
import CreateProject from '../actions/project/CreateProject.vue';
import MainPage from '../actions/site/MainPage.vue';
import UpdateProject from '../actions/project/UpdateProject.vue';
import TaskIndex from '../actions/task/TaskIndex.vue';
import Blog from '@/actions/blog/Blog.vue';
import Post from '@/actions/blog/Post.vue';
import NoApp from '@/actions/site/NoApp.vue';

Vue.use(Router);

/* eslint-disable object-curly-newline, max-len */
const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'MainPage', component: MainPage, meta: { layout: 'wide-layout' } },
    { path: '/no-app', name: 'NoApp', component: NoApp },
    // user
    { path: '/signin', name: 'SignIn', component: SignIn, props: true },
    { path: '/signup', name: 'SignUp', component: SignUp },
    { path: '/password-recovery', name: 'PasswordRecovery', component: PasswordRecovery, meta: { permission: '@' } },
    { path: '/my-profile', name: 'MyProfile', component: MyProfile, meta: { permission: '@' } },
    // site
    { path: '/blog', name: 'Blog', component: Blog },
    { path: '/blog/:id', name: 'Post', component: Post, props: true },
    // project
    { path: '/project/create', name: 'CreateProject', component: CreateProject, props: true, meta: { permission: '@' } },
    { path: '/:username/projects', name: 'ProjectsList', component: ProjectsList, props: true, meta: { permission: '@' } },
    { path: '/:username/:projectname', name: 'ProjectView', component: ProjectView, props: true, meta: { permission: '@' } },
    { path: '/:username/:projectname/members', name: 'Members', component: Members, props: true, meta: { permission: '@' } },
    { path: '/:username/:projectname/settings', name: 'Settings', component: Settings, props: true, meta: { permission: '@' } },
    { path: '/:username/:projectname/update', name: 'UpdateProject', component: UpdateProject, props: true, meta: { permission: '@' } },
    { path: '/:username/:projectname/tasks/:taskLiteralId?', name: 'TaskIndex', component: TaskIndex, props: true, meta: { permission: '@', layout: 'wide-without-footer-layout' } },
    // { path: '/:username/:projectname/tasks', name: 'TaskIndex', component: TaskIndex, props: true, meta: { permission: '@', layout: 'wide-without-footer-layout' } },
  ],
});

router.beforeEach((to, from, next) => {
  const { permission } = to.meta;

  if (typeof permission !== 'undefined') {
    if (!store.getters.isGuest) {
      if (permission === '@' || store.getters.user.permissions.find(x => x === permission)) {
        next();
      } else {
        next('/403');
      }
    } else if (permission === '?') {
      next();
    } else {
      next('/signin');
    }
    return;
  }
  next();
});

export default router;
