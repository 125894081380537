<template>
<div>
  <form @submit.prevent.stop>
    <div class="d-flex flex-column flex-md-row align-items-baseline justify-content-start py-2 pl-1">
      <screen-toggle class="ml-n2 mr-2 d-none d-md-block" />
      <input type="text"
             class="form-control"
             style="width: 160px;"
             v-model="filters.query"
             placeholder="Поиск..."
             @keyup="submit">

      <b-dropdown variant="link" toggle-tag="div"
                  dropdown
                  class="pointer"
                  text="Сортировка"
                  toggle-class="text-decoration-none">
        <template v-slot:default>
        <a class="dropdown-item"
           v-for="(label, sortOrder) in sortOrders"
           :key="sortOrder"
           @click="filters.sortOrder = sortOrder; submit()"
           :class="{active: filters.sortOrder === sortOrder}"
        >
          {{label}}
        </a>
        </template>
      </b-dropdown>

      <div class="position-relative">
        <div class="red-circle" v-if="filters.statuses.length !== 0"/>
        <b-dropdown variant="link" toggle-tag="div"
                    dropdown
                    class="pointer"
                    text="Статус"
                    toggle-class="text-decoration-none"
                    v-if="statuses !== null"
        >
          <template v-slot:default>
          <a class="dropdown-item"
             v-for="status in statuses.statuses"
             :key="status.id"
             @click="toggleStatus(status)"
             :class="{active: filters.statuses.includes(status.id)}"
          >
            {{status.label}}
          </a>
          </template>
        </b-dropdown>
      </div>

      <div class="position-relative">
        <div class="red-circle" v-if="filters.members.length !== 0"/>
        <b-dropdown variant="link" toggle-tag="div"
                    dropdown
                    class="pointer"
                    text="Участники"
                    toggle-class="text-decoration-none"
                    v-if="members !== null"
        >
          <template v-slot:default>
          <a class="dropdown-item"
             v-for="connection in members"
             :key="connection.user.id"
             @click="toggleMember(connection.user)"
             :class="{active: filters.members.includes(connection.user.id)}"
          >
            {{connection.user.username}}
          </a>
          </template>
        </b-dropdown>
      </div>
    </div>
  </form>
</div>
</template>

<script>
import { BDropdown } from 'bootstrap-vue';
import ScreenToggle from '@/views/task/ScreenToggle.vue';

/* eslint-disable no-restricted-syntax, guard-for-in */
export default {
  name: 'TaskFilters',
  components: {
    ScreenToggle,
    'b-dropdown': BDropdown,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      filters: {
        query: '',
        statuses: [],
        members: [],
        sortOrder: '',
        executor: null,
      },
      sortOrders: {
        '': 'Сначала новые',
        createdAt: 'Сначала старые',
        '-priority': 'Сначала с высоким приоритетом',
        priority: 'Сначала с низким приоритетом',
      },
    };
  },
  watch: {
    $route() {
      this.loadFilters();
    },
  },
  computed: {
    statuses() {
      return this.$store.getters['task/statuses'];
    },
    members() {
      if (this.project === null) {
        return null;
      }
      return this.$store.getters['project/members'][this.project.id];
    },
  },
  mounted() {
    this.loadFilters();
  },
  methods: {
    submit() {
      const filters = JSON.parse(JSON.stringify(this.filters));
      const queryObject = {};
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in filters) {
        let value = filters[key];
        if (value === null || (typeof value.length !== 'undefined' && value.length === 0)) {
          // eslint-disable-next-line no-continue
          continue;
        }
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        queryObject[key] = value;
      }

      this.$router.push({ name: 'TaskIndex', query: queryObject });
    },
    toggleStatus(status) {
      if (this.filters.statuses.includes(status.id)) {
        this.filters.statuses.splice(this.filters.statuses.indexOf(status.id), 1);
      } else {
        this.filters.statuses.push(status.id);
      }
      this.submit();
    },
    toggleMember(user) {
      if (this.filters.members.includes(user.id)) {
        this.filters.members.splice(this.filters.members.indexOf(user.id), 1);
      } else {
        this.filters.members.push(user.id);
      }
      this.submit();
    },
    loadFilters() {
      for (const key in this.$route.query) {
        if (Array.isArray(this.filters[key])) {
          const arrayOfStrings = this.$route.query[key].split(',');
          for (const i in arrayOfStrings) {
            const item = arrayOfStrings[i];
            if (typeof item === 'string' && item.match(/^\d+$/) !== null) {
              arrayOfStrings[i] = Number(item);
            }
          }
          this.$set(this.filters, key, arrayOfStrings);
        } else {
          this.$set(this.filters, key, this.$route.query[key]);
        }
      }
    },
  },
};
</script>

<style scoped>
.red-circle {
  position: absolute;
  top: 4px;
  left: 5px;
  background-color: var(--info);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
</style>
