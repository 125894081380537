import TaskRepository from '@/repository/TaskRepository';

let screen = localStorage.getItem('task_containerScreen');
if (!screen) {
  screen = false;
}

export default {
  namespaced: true,
  state: {
    containerScreen: Boolean(Number(screen)),
    statuses: null,
    lineOptions: {},
  },
  getters: {
    screen: state => state.containerScreen,
    statuses: state => state.statuses,
    lineOptions: (state) => {
      const lineOptions = localStorage.getItem('task_lineOptions');
      if (lineOptions) {
        state.lineOptions = JSON.parse(lineOptions);
      }

      return state.lineOptions;
    },
  },
  mutations: {
    toggleScreen(state) {
      state.containerScreen = !state.containerScreen;
      localStorage.setItem('task_containerScreen', Number(state.containerScreen));
    },
    setStatuses(state, statuses) {
      state.statuses = statuses;
    },
    setLineOptions(state, value) {
      state.lineOptions = value;
      localStorage.setItem('task_lineOptions', JSON.stringify(state.lineOptions));
    },
  },
  actions: {
    loadStatuses({ commit }) {
      return new Promise((resolve) => {
        TaskRepository.getStatuses((result) => {
          commit('setStatuses', result);
          resolve();
        });
      });
    },
  },
};
