const OWNER = 1;
const MAINTAINER = 2;
const GUEST = 3;

function isOwner(role) {
  return OWNER === Number(role);
}

export default {
  OWNER,
  MAINTAINER,
  GUEST,
  isOwner,
};
