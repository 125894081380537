import http from './Http';

function list(data, onSuccess) {
  http.get('/blog/list', data, onSuccess);
}

function view(id, onSuccess) {
  http.get('/blog/view', { id }, onSuccess);
}

export default {
  list,
  view,
};
