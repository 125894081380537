<template>
<div>
  <app-header/>
    <div class="flex-shrink-0">
      <div class="container">
      <slot></slot>
      </div>
    </div>
  <app-footer/>
</div>
</template>

<script>
import AppHeader from '../AppHeader.vue';
import AppFooter from '../AppFooter.vue';

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
  },
};
</script>

<style scoped>

</style>
